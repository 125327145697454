import { useTranslation } from "react-i18next";
import { useContext, useState, useEffect } from "react";
import { TaskContext } from "../AppStates";
import Logins from "../functions/Log-in";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from '@greatsumini/react-facebook-login';
import Logo from "../logo.svg";
import Modal from 'react-modal';
import Prisijungimas from "./Prisijungimas";
import Registracija from "./Registracija";

export default function Login() {
    const { t } = useTranslation()
    const [main, setMain] = useState('prisijungimas')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    // const handleClose = () => setModalIsOpen(false);
    const handleShow = () => setModalIsOpen(true);
    const [info, setInfo] = useState({})
    return (

        <div className="centerContainer fullScreen column">
            <div className="loginBg">

                <main className="cntr">
                    <button onClick={() => { setMain('prisijungimas') }} className={`chooseBtn ${main == "prisijungimas" ? ('') : ('off')}`}>{t('Prisijungimas')}</button>
                    <button onClick={() => { setMain('registracija') }} className={`chooseBtn1 ${main == "registracija" ? ('') : ('off')}`}>{t('Registracija')}</button>
                    {main == 'prisijungimas' ? (
                        <Prisijungimas />
                    ) :
                        (<Registracija />)}
                </main>
            </div>
        </div>
    );
}


