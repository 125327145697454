
import { Link } from "react-router-dom";
import Login from "./routes/Login";
import App from "./App"
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TaskContext } from "./AppStates";
import Main from "./routes/main";
import Kandidatai from "./routes/kandidatai";
import DB from "./routes/db";
import Saved from "./routes/saved";
import Ads from "./routes/ads";
import Profile from "./routes/profile";
import Logout from "./routes/logout";
import Logins from "./functions/Log-in";
const Navigation = () => {
  const { routas, setTasks, setUser, setAccess } = useContext(TaskContext)
  const [data, setData] = useState('')

  
  const letsLogIn = async () => {
    try {
      Logins({ data, routas, setTasks, setUser })
    }
    catch (e) { console.log(e) }
  }

  
  useEffect(() => {
    try {
      if (getCookie('scobo_token') != undefined ) {
        setData({ access_token: getCookie('scobo_token') })
        setAccess({ access_token: getCookie('scobo_token') })
      }
    }
    catch (e) { console.log(e) }
  }, [])

  useEffect(() => {
    letsLogIn()
  }, [data])
  
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="saved" element={<Saved />} />
      <Route path="db" element={<DB />} />
      <Route path="ads" element={<Ads />} />
      <Route path="profile" element={<Profile />} />
      <Route path="logout" element={<Logout />} />
    </Routes>

  )
}
export default Navigation

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}