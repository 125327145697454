export const ua = {
    translations: {
        welcome: "Привіт",
        toliau: "Далі",
        pradeti: "Розпочати",
        ar_darbdavys_darbuotojas_text: "Ви роботодавець або шукаєте роботу",
        ieskau_Darbo: "Я шукаю роботу",
        esu_darbdavys: "Я роботодавець",
        Prisijungimas: "Вхід",
        Registracija: "Реєстрація",
        Registruotis: "Зареєструватися",
        google: "Google",
        facebook: "Facebook",
        arba_registruokis: "Або зареєструйтеся",
        email: "Електронна скринька",
        atgal: "Назад",
        praleisti: "Пропустити",
        Prie_Scovo: "Приєднатися до Scobo",
        prisijungimas: "Вхід",
        registracija: "Реєстрація",
        slaptazodis: "Пароль",
        pamirsauSlaptazodi: "Забули пароль",
        Prisijungti: "Вхід",
        Pakartoti_slaptazodis: "Повторіть пароль",
        Vardas: "Ім'я",
        Pavarde: "Прізвище",
        Labas: "Вітаю!",
        Laukiame: "Чекаємо вашого з'єднання",
        Pasirinkite_issilavinimo_lygi: "Виберіть освітній рівень",
        ar_turite_patirties: "Чи є у Вас досвід роботи у цій сфері",
        taip: "Так",
        ne: "Ні",
        kiek_metu: "Скільки років досвіду у цій сфері",
        visi_miestai: "Всі міста",
        nuotoliniu: "Віддалена робота",
        hibridinis_darbas: "Гібридна робота",
        nuo: "Від",
        iki: "До",
        darbo_aprasymas: "Опис посади та вимоги",
        Aprasymas: "Опис",
        kontaktinis_el: "Електронна скринька",
        tel: "Номер телефону",
        kotaktu_sutikimas: "Даю згоду на використання представлених мною контактів для зв'язку",
        aprasymas_apie_save: "Додайте короткий опис про себе",
        uzpildyti_apie_imone: "Заповніть інформацію про компанію",
        imones_pavadinimas: "Назва компанії",
        apie_imone: "Про компанію",
        svetaine: "Посилання на сайт",
        darbuotoju_sk: "Кількість співробітників",
        date: "дд/мм/рр",
        teisineForma: "Юридична форма компанії",
        veiklos_sritys: "Сфера діяльності",
        pridekite_darbo_pasiulyma: "Додайте пропозицію про роботу",
        pradekite_naudotis: "Додайте пропозицію і почніть користуватися Scobo!",
        prideti: "Додайте",
        darbo_pareigos: "Заповнити посаду",
        grizti: "Назад",
        klausimas: "Питання",
        reikalingas_CV: "Вам потрібне резюме співробітника",
        reikalingas_Linkedin: "Вам потрібне посилання на LinkedIn",
        Profilis: "Профіль",
        Sutape: "Збіг",
        istrinti: "Видалити профіль",
        SutapoteSu: "У вас є збіги з",
        Susisiekti: "Зв'яжіться з нами",
        atsijungti: "Вийти",
        asmenine_informacija: "Особиста інформація",
        skelbimo_informacija: "Інформація профілю",
        slaptazodzio_keitimas: "Зміна паролю",
        pagalba: "Допомога",
        pranesimai: "Повідомлення",
        issaugoti: "Зберегти",
        sena_slaptazodi: "Введіть старий пароль",
        nauja_slaptazodi: "Введіть новий пароль",
        nauja_pakartoti_slaptazodi: "Повторіть новий пароль",
        Prideti_Skelbima: "Додати нове оголошення",
        isikurimo_data: "Дата створення",
        Sutape_darbuotojai: "Співробітники, що збіглися",
        miestas: "Місто",
        atlyginimas: "Заробітна платня (брутто)",
        aprasymas: "Опис",
        sritis: "Сфера",
        daugiau: "Більше",
        susiekite_elp: "елекронною скринькою",
        susiekite_numeriu: "по телефону",
        turi_darbo_Skelbima: "Хоче запропонувати Вам роботу",
        Sutape_darbdaviai: "Роботодавці, що збіглися",
        kreipiantis: "Звертаючись по електронній пошті, просимо",
        add_CV: "Додати резюме",
        ir: "і",
        ats_kls: "Дайте відповідь на ці питання",
        kontaktinis: "Вкажіть електронну скриньку",
        darbuotoju_baze: "База співробітників",
        issaugoti_darbuotojai: "Зберегти працівників",
        darbo_sritis_filr: "Сфера діяльності",
        kalba_filtr: "Мова",
        filtruoti: "Застосувати фільтри",
        panaikinti_filtra: "Видалити фільтри",
        istrinti_issaugota: "Видалити",
        metai: "Рік",
        nesutampa_psw: "Паролі не співпадають",
        poke: "Підмигнути",
        priregistruotas: "Ви знайдете посилання для підтвердження у своїй електронній скриньці",
        praneskite_skelbima: "Повідомити працівника про вакансію",
        poreikis: "Потреба",
        ar_tikrai_atsijungti: "Ви впевненні, що хочете вийти",
        ar_tikrai_istrinti: "Ви впевненні, що хочете видалити",
        uzdaryti: "Закрити",
        sutinku_prisijunimo: "Я згоден з правилами входу",
        darbuotojo_aprasymas: "Напишіть коротко про себе",
        klausimai_kandidatam: "Ви можете хадати питання кандидатам, відповіді будуть додані до їх анкет",
        Filtras: "Шукати",
        istrinti_sutapusi: "Видалити",
        darbuotojo_informacija: "Інформація про працівника",
        filtras: "Фільтр",
        skelbimai: "Оголошення",
        darbuotojai: "Працівники",
        susipazinau_su_p: "Я ознайомився з політикою щодо персональних даних",
        Jau_priregistruotas: "Цей електронний лист пошта вже існує",
        blogai_ivesti_duomenys: "Введені неправильні дані",
        issaugoti_darbdaviai: "Зберегти оголошення",
        praneskite_skelbima_darbuotojas: "Розкажіть про себе роботодавцю",
        Jus_issaugojo: "Вас зберегли",
        jumis_susidomejo: "Компанія зацікавлена вами",
        susi: "Зв'яжіться з нами",
        darbuotoju_baze_sutikimas: "Я погоджуюсь, що особиста інформація буде відображатися в базі даних роботодавців",
        skelbimu_nera: "Тимчасово немає відповідних кандидатів, спробуйте пізніше!",
        nera_jums_tinkanciu: "Тимчасово недоступні оголошення про вакансію, поверніться найближчим часом!",
        siulomi_darbuotojai: "Потенційні кандидати",
        siulomi_darbdaviai: "Оголошення про вакансії",
        pasirinkti_kalba: "Виберіть мову",
        studiju_sritis: "Сфера навчання",
        issiusti: "Відправити",
        skelbimas_ijungtas: "Оголошення видно",
        skelbimas_isjungtas: "Оголошення не видно",
        psw_visi_tokie_pat: "Новий пароль такий самий, як і старий",
        psw_pakeistas: "Пароль змінено",
        psw_skirtingi: "Новий пароль не співпадає",
        psw_blogas: "Неправильний пароль",
        blogi_prisijungimas: "Невірні дані для входу",
        susipazinau_darbdaviams: "Я підтверджую, що вся інформація, яку я надаю в програмі SCOBO, правильна. Компанія несе повну відповідальність за достовірність наданої інформації та за забезпечення відповідного робочого місця відповідно до правових актів Литовської Республіки.",
        susipazinau_darbuotojams: "Я підтверджую, що створюю обліковий запис на своє власне ім’я і що вся інформація, яку я надаю в додатку SCOBO, є правильною. Я несу повну відповідальність за достовірність наданої інформації.",
        kita: "Інше",
        duomenu_politika: "Політика даних",
        passikeisti_varda_pavarde: "Будь ласка, оновіть свою особисту інформацію",
        darbo_skelbimo: "Інформація про вакансії",
        skelbimas_atnaujintas: "Оголошення успішно оновлено",
        nera_issaugotos: "Ви ще не зберегли жодного кандидату",
        svarbus_pakeitimai: "Є важливі зміни, запрошуємо оновити програму. Ви можете зробити це тут",
        atsisiusti: "завантажити",
        pavarde_filtras: "Прізвище",
        vardas_filtras: "Ім'я",
        netinkantis: 'Поки що відсутні оголошення, які  вам підходять, але перегляньте ось ці',
        tikDarbdaviam: 'ЛОГІН компанії',
        reguliarios_komforto_pertraukos: 'Доступні регулярні комфортні перерви',
        pritaikyta_judeti_vezimelyje: 'Приміщення обладнане для інвалідів на візках',
        atviri_poreikiams: "Ми відкриті для адаптації умов праці до індивідуальних потреб співробітників",
        norite_teskstas_atsirastu: 'Поставте прапорець, якщо ви хочете, щоб цей текст відображався поруч із вашим оголошенням',
        languages: [
            "en",
            "lt",
            "ru",
            "ar",
            "ua",
        ],
        languages_full: [
            "English",
            "Lietuvių",
            "Русский",
            "العربية",
            "Українська",
        ],
        darbuotojo_options_top: [
            "Освіта",
            "Напрямок навчання",
            "Сфера роботи",
            "Досвід",
            "Тип контракту",
            "Посада",
            "Мова",
            "Місто",
            "Зарплата",
            "Опис",
            "Контакти",
        ],
        issilavinimas: [
            "Початкова освіта",
            "Молодша середня освіта",
            "Вища середня освіта",
            "Професійна освіта",
            "Вища освіта",
        ],
        sritys: [
            "Медицина",
            "Стоматологія",
            "Фармакологія",
            "Харчування",
            "Реабілітація",
            "Медсестринство",
            "Науки про життя",
            "Сільське господарство та ветеринарія",
            "Природничі науки",
            "Геологія",
            "Біологія",
            "Ботаніка",
            "Зоологія",
            "Генетика",
            "Спорт",
            "Хімія",
            "Фізика",
            "Довкілля",
            "Математика та ІТ",
            "Інженерія",
            "Кулінарія",
            "Суспільні науки",
            "Право",
            "Бізнес та менеджмент",
            "Фінанси",
            "Бухгалтерія",
            "Туризм",
            "Психологія",
            "Соціологія",
            "Політологія",
            "Економіка",
            "Комунікації",
            "Освіта та навчання",
            "Філологія",
            "Мистецтво",
            "Історія",
            "Філософія",
            "Теологія",
            "Культурологія",
            "Інше",
        ],
        darbo_sritis: [
            "Сільське господарство",
            "Лісове господарство",
            "Рибне господарство",
            "Харчова промисловість",
            "Будівництво",
            "Оптова та роздрібна торгівля",
            "Туризм",
            "Гостинність",
            "Харчування",
            "Логістика",
            "Зв'язки з громадськістю",
            "ЗМІ",
            "Фінанси",
            "Страхування",
            "Нерухомість",
            "Наука",
            "Фармакологія",
            "Організація",
            "Управління",
            "Продажі",
            "Державна політика",
            "Оборона",
            "Адміністрування",
            "Інформаційні технології",
            "Освіта",
            "Охорона здоров'я",
            "Соціальна робота",
            "Мистецтво",
            "Розваги",
            "Спорт",
            "Обслуговування клієнтів",
            "Кадри",
            "Недержавний сектор",
            "прибирання",
        ],
        patirtis_metais: [
            "0-1",
            "1-3",
            "3-5",
            "5-10",
            "10 i більше",
        ],
        sutartis: [
            "Трудовий договір",
            "Індивідуальна діяльність",
            "Бізнес сертифікат",
            "Інше",
            "Будь-який тип договору",
        ],
        etatas: [
            "025",
            "05",
            "075",
            "1",
        ],
        kalbos: [
            "литовська",
            "англійська",
            "російська",
            "німецька",
            "більше",
            "французька",
            "данська",
            "норвезька",
            "арабська",
            "іспанська",
            "італійська",
            "шведська",
            "китайська",
            "індійська",
            "іврит",
            "перська",
            "пушту",
            "турецька",
            "суахілі",
        ],
        kalbos_lygiai: [
            "Носій рідної мови",
            "Вільне сплікування",
            "Середній рівень",
            "Початковий рівень",
        ],
        miestai: [
            "Vilnius",
            "Kaunas",
            "Klaipėda",
            "Šiauliai",
            "Panevėžys",
            "Alytus",
            "Akmenė",
            "Anykščiai",
            "Ariogala",
            "Baltoji Vokė",
            "Birštonas",
            "Biržai",
            "Daugai",
            "Druskininkai",
            "Dūkštas",
            "Dusetos",
            "Eišiškės",
            "Elektrėnai",
            "Ežerėlis",
            "Gargždai",
            "Garliava",
            "Gelgaudiškis",
            "Grigiškės",
            "Ignalina",
            "Jieznas",
            "Jonava",
            "Joniškėlis",
            "Joniškis",
            "Jurbarkas",
            "Kaišiadorys",
            "Kalvarija",
            "Kavarskas",
            "Kazlų Rūda",
            "Kėdainiai",
            "Kelmė",
            "Kybartai",
            "Kretinga",
            "Kudirkos Naumiestis",
            "Kupiškis",
            "Kuršėnai",
            "Lazdijai",
            "Lentvaris",
            "Linkuva",
            "Marijampolė",
            "Mažeikiai",
            "Molėtai",
            "Naujoji Akmenė",
            "Nemenčinė",
            "Neringa",
            "Obeliai",
            "Pabradė",
            "Pagėgiai",
            "Pakruojis",
            "Palanga",
            "Pandėlys",
            "Panemunė",
            "Pasvalys",
            "Plungė",
            "Priekulė",
            "Prienai",
            "Radviliškis",
            "Ramygala",
            "Raseiniai",
            "Rietavas",
            "Rokiškis",
            "Rūdiškės",
            "Salantai",
            "Seda",
            "Simnas",
            "Skaudvilė",
            "Skuodas",
            "Smalininkai",
            "Subačius",
            "Šakiai",
            "Šalčininkai",
            "Šeduva",
            "Šilalė",
            "Šilutė",
            "Širvintos",
            "Švenčionėliai",
            "Švenčionys",
            "Tauragė",
            "Telšiai",
            "Tytuvėnai",
            "Trakai",
            "Troškūnai",
            "Ukmergė",
            "Utena",
            "Užventis",
            "Vabalninkas",
            "Varėna",
            "Varniai",
            "Veisiejai",
            "Venta",
            "Viekšniai",
            "Vievis",
            "Vilkaviškis",
            "Vilkija",
            "Virbalis",
            "Visaginas",
            "Zarasai",
            "Žagarė",
            "Žiežmariai",
        ],
        atlyginimai: [
            "200",
            "250",
            "300",
            "350",
            "450",
            "500",
            "550",
            "600",
            "650",
            "700",
        ],
        darbdavio_top_steps: [
            "Поле пошуку роботи",
            "Назва",
            "Позиція",
        ],
        darbdavio_top_Pozicija: [
            "Назва роботи",
            "Посада",
            "Вид контракту",
        ],
        darbdavio_top_Sutarties: [
            "Назва роботи",
            "Посада",
            "Ставка",
        ],
        darbdavio_top_Etatas: [
            "Вид контракту",
            "Ставка",
            "Мови",
            "Фріланс",
        ],
        darbdavio_top_Kalbos: [
            "Ставка",
            "Мови",
            "Місто",
        ],
        darbdavio_top_Miestas: [
            "Мови",
            "Місто",
            "Заробітня плата",
        ],
        darbdavio_top_Atlyginimas: [
            "Місто",
            "Заробітня плата",
            "Опис посади",
        ],
        darbdavio_top_Aprasymas: [
            "Заробітня плата",
            "Опис посади",
            "Додаткові питання",
        ],
        darbdavio_top_Klausimai: [
            "Опис посади",
            "Додаткові питання",
            "Дійсний до",
        ],
        darbdavio_top_SkelbimoLaikas: [
            "Опис посади",
            "Додаткові питання",
            "Дійсний до",
        ],
        darbo_pozicijos: [
            "Молодший працівник",
            "Менеджер середньої ланки",
            "Старший менеджер",
            "Старший менеджер",
            "Будь-який",
        ],
        sutarties_pobudis: [
            "Трудовий договір",
            "Індивідуальна діяльність",
            "Бізнес свідоцтво",
            "Стажування або волонтерський договір",
            "Будь-який вид контракту",
        ],
    }
}