import Select from 'react-select';
import {matchingas} from './matching'

const skelbimuApdirbimas= async (user,skelbimai,routas)=>{
    var ats =0
    var values =[]
    var uSkelbimai = await skelbimus(user._id,routas)
    return uSkelbimai
    console.log(uSkelbimai)
    Object.keys(user).forEach((uItem,uIndex)=>{
        Object.keys(skelbimai[0]).forEach((item,index)=>{
          // if(skelbimai[arrIndex].item == )
          // ats=ats+1
          // ats+= skelbimai[arrIndex].
         if (uItem==item){
  
           ats+=+1
         }
       })
      })
    
    return uSkelbimai
  }
  
  
  const skelbimus = async (id,routas, matching = [],setter) => {
    let get_skelbimai = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    let get_listing = routas + 'skelbimai/' + id;
    try {
        const response = await fetch(get_listing, get_skelbimai)
        const json = await response.json().then((data) => {
          if (response.status == 200) {
            // if (matching!= []){
            //   return matchingas(data,matching,true)
             
            // } 
            // else{

              return  data[0]
            // }
          }
        })
    } catch (error) {
        console.error(error);
    }
  };
  
  export const getCard = async (routas,user,setter,setter2) => {
    let cardDomain = routas + 'worker-listing/darbdaviui/' + user._id
    let requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(cardDomain, requestOptions)
      .then(async response => {
        await response.json().then( data => {
          if (data.statusCode != 500 && data != []) {
            console.log(data)
            setter(data.splice(1))
            setter2(data[0])
            // console.log(skelbimus(user._id,routas,data))
            // skelbimus(user._id,routas,data).then((me)=>console.log(me))
          }
        })
      })
      .catch(error => {
        console.error('There was an error!', error);
        return false
      });
  }

 export const perKalbleli = (item, kas,t) => {
     
    if (item && item != 'undefined' && item != '' && item != undefined) {
      let visos = item.split(',')
      let html = ''
      visos.forEach((element, index) => {
        if (element == 'nuotoliniu') { html +=  t('nuotoliniu') }
        else if (element == 'nuotoliniu') { html += t('visi_miestai') }
        else {
          if (element != '' && kas[element] != undefined)
            if (index != visos.length - 1 && visos[index + 1] != '') {
              html += kas[element] + ', '
            }
            else {
              html += kas[element]
            }
        }
      });
      return html
    }
    else {
      return ''
    }
  }

  export const kalbos = (item,t) => {
    if (item && item != 'undefined' && item != '' && item != undefined) {
      let visos = item.split(',')
      let html = ''
      visos.forEach((element, index) => {
        if (element != '') {
          if (index != visos.length - 1 && visos[index + 1] != '') {
            html += t('kalbos')[element.split(':')[0]] + ': ' + t('kalbos_lygiai')[element.split(':')[1]] + "; ";
          }
          else {
            html += t('kalbos')[element.split(':')[0]] + ': ' + t('kalbos_lygiai')[element.split(':')[1]]
          }
        }
      });
      return html
    }
    else {
      return ''
    }
  }
  export const Langelis = (checker, pavadinimas, aprasas) => {
    if (!checker || checker == 'undefined' || checker == '' || checker == undefined) { return }
    if (aprasas != undefined)
      aprasas = aprasas.replace('undefined', '')


    return (
      <div>
        <div >
          <div >
            <h4 className="inModalInfo">{pavadinimas}</h4>
            <h5 className="inModalInfo1">{aprasas.replace('\\n', '\n').replace(' \\n', '\n').replace('. \\n', '.')}</h5>
          </div>
        </div>
      </div>
    )
  }
 export const issaugoti = async (routas, mano, darbuotojo) => {
    const get_listing = routas + 'users/save/' + mano + '/' + darbuotojo;
    const get_skelbimai = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
    }

    try {
      const response = await fetch(get_listing, get_skelbimai)
    } catch (error) {
      console.error(error);
    }
  };
  
  export const istrint = async (routas,mano, darbuotojo) => {
    const get_listing = routas + 'users/remove/' + mano + '/' + darbuotojo;
    const get_skelbimai = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    }

    try {
        const response = await fetch(get_listing, get_skelbimai)
        const json = await response.json().then(data => {
          getCard()
        })
    } catch (error) {
        console.error(error);
    }
};
 export const atmesti = async (routas, ManoId, darbuotojoId ) => {
        var atmesti_listing = routas + 'worker-listing/dislike/' + darbuotojoId + '/' + ManoId;
        var atmesti_skelbimai = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
        }
        try {
            const response = await fetch(atmesti_listing, atmesti_skelbimai)
            const json = await response.json().then(data => {
                return true
            })
        } catch (error) {
            console.error(error);
        }
    }

  export  const poke = async (routas,userS,listing_id) => {
        const bla = routas + 'users/poke/' + userS + '/' + listing_id;
        const bel = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                employer: true
            })
        }
        try {
            const response = await fetch(bla, bel)
        } catch (error) {
            console.error(error);
        }
    }
 export  const gautiSkelbimus = async (routas,naudotojas,setter) => {
        const get_skelbimai = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        const get_listing = routas + 'skelbimai/' + naudotojas._id;
        try {
            const response = await fetch(get_listing, get_skelbimai)
            const json = await response.json().then(data => {
                setter(data)
           
            })
        } catch (error) {
            console.error(error);
        }
    };

 export const selectorius = (options,change,placeholder,pav,setPav)=>{
   
    var readyOptions=[]
    var value=''
    options.forEach((e, i) => {
        readyOptions.push({ value: e._id, label: e.pareigos })
    })
    
    const element = ( <div className="checkIt" style={{maxWidth:'90%',margin:'auto'}}>
    <Select
        placeholder={placeholder}
        className="selectFromF"
        // isMulti={true}/
        value={pav}
        onChange={(i)=>{
            setPav(i.pareigos)
            change(i)
        }}
        options={readyOptions}
    />
</div>)

        return element
    }