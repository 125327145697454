import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskContext } from '../AppStates';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import Modal from 'react-modal';
import { Button, ToggleButton, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function Ads() {
    const { user, routas } = useContext(TaskContext);
    const { t } = useTranslation()
    // console.log(user)
    const [skelbimai, setSkelbimai] = useState(['empty'])
    const [loading, setLoading] = useState(true)
    const [now, setNow] = useState({})



    const skelbimus = async () => {
        let get_skelbimai = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        let get_listing = routas + 'skelbimai/' + user._id;
        try {
            const response = await fetch(get_listing, get_skelbimai)
            const json = await response.json().then(data => {
                if (response.status == 200) {
                    setSkelbimai(data)
                    setLoading(false)
                }
            })
        } catch (error) {
            console.error(error);
        }
    };
    // useEffect(() => { skelbimus() }, [])
    useEffect(() => { }, [skelbimai])
    if (loading == true) {
        if (user != '') { skelbimus() }
    }
    useEffect(() => {
        setNow(now)
    }, [now])

    // maybe not should be here


    var sritys = []
    var darboPozicija = []
    var sutartisD = []
    var etatasO = []
    var kalbaO = []
    var miestasO = []
    var kalbosLygiai = t('kalbos_lygiai')

    t('darbo_sritis').forEach((e, i) => {
        sritys.push({ value: i, label: e })
    })
    sritys = sritys.sort((a, b) => a.label > b.label ? 1 : -1)
    t('darbo_pozicijos').forEach((e, i) => {
        darboPozicija.push({ value: i, label: e })
    })

    t('sutartis').forEach((e, i) => {
        sutartisD.push({ value: i, label: e })
    })
    t('etatas').forEach((e, i) => {
        etatasO.push({ value: i, label: e })
    })
    t('kalbos').forEach((e, i) => {
        if (i != 4)
            kalbaO.push({ value: i, label: e })
    })
    t('miestai').forEach((e, i) => {
        miestasO.push({ value: i, label: e })
    })
    const apdoroti = (item) => {
        if (item == null) { return }
        if (item != null || item != '')
            try {
                let data = ''
                item.forEach((e, i) => {
                    if (i != item.length - 1) {
                        data += e.value + ','
                    } else { data += e.value }
                })
                return data
            }
            catch (e) { console.log(e) }
    }
    const apdorotiK = (item) => {
        if (item == null) { return }

        if (item != null || item != '' || item != null)
            try {
                let data = ''
                item.forEach((e, i) => {
                    if (i != item.length - 1) {
                        data += e.value + ':' + pasirinktasLygis.pasirinktasLygis[e.label] + ','
                    } else { data += e.value + ':' + pasirinktasLygis.pasirinktasLygis[e.label] }
                })
                return data
            }
            catch (e) { console.log(e) }
    }
    const dublikuoti = async () => {
        if (pareigos == "") { return }
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:
                JSON.stringify({
                    atlygis: nuo + '-' + iki,
                    darbo_sritis: apdoroti(sritysD),
                    pareigos: pareigos,
                    darbo_pozicija: apdoroti(darboP),
                    sutarties_pobudis: apdoroti(sutartis),
                    etatas: apdoroti(etatas),
                    kalbos_ir_ju_lygiai: apdorotiK(kalba),
                    miestas: apdoroti(miestas) + `${checked ? ',visi_miestai' : ''}` + `${checked2 ? ',nuotoliniu' : ''}` + `${checked4 ? ',hibridinis' : ''}`,
                    darbo_aprasymas: darboA,
                    kontaktinis_el_pastas: kontaktinis,
                    galiojimo_laikas: date,
                    papildomi_klausimai: klausimai,
                    is_CV: cv,
                    is_Linkedin: linkedin,
                    kita_kalba: kita_kalba,
                    atvirumo_tekstas: atvirumo_tekstas,
                    pritaikyta_judeti_vezimelyje: vezimeliai,
                    reguliarios_komforto_pertraukos: komforto
                })
        };
        let create_listing = routas + 'skelbimai/' + user._id;
        try {
            console.log(JSON.parse(requestOptions.body))
            const response = await fetch(create_listing, requestOptions)
            const json = await response.json().then(data => {
                if (data.statusCode != 500) {
                    console.log(data)
                    skelbimus()
                    // alert(t('skelbimas_dublikuotas'))
                    if (Object.keys(now).length == 0) {
                        window.location.reload()
                    }
                }
                //         return
            })
        } catch (error) {
            console.error(error);
        }
    };
    const create = async () => {
        console.log(kita_kalba)
        let requestOptions = {
            method: Object.keys(now).length != 0 ? 'PATCH' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:
                JSON.stringify({
                    atlygis: nuo + '-' + iki,
                    darbo_sritis: apdoroti(sritysD),
                    pareigos: pareigos,
                    darbo_pozicija: apdoroti(darboP),
                    sutarties_pobudis: apdoroti(sutartis),
                    etatas: apdoroti(etatas),
                    kalbos_ir_ju_lygiai: apdorotiK(kalba),
                    miestas: apdoroti(miestas) + `${checked ? ',visi_miestai' : ''}` + `${checked2 ? ',nuotoliniu' : ''}`,
                    darbo_aprasymas: darboA,
                    kontaktinis_el_pastas: kontaktinis,
                    galiojimo_laikas: date,
                    papildomi_klausimai: klausimai,
                    is_CV: cv,
                    is_Linkedin: linkedin,
                    kita_kalba: kita_kalba,
                    atvirumo_tekstas: atvirumo_tekstas,
                    pritaikyta_judeti_vezimelyje: vezimeliai,
                    reguliarios_komforto_pertraukos: komforto,
                })
        };
        let create_listing = routas + 'skelbimai/' + user._id;
        if (Object.keys(now).length != 0) {
            create_listing = routas + 'skelbimai/' + now._id + '';
        }
        try {
            console.log(JSON.parse(requestOptions.body))
            const response = await fetch(create_listing, requestOptions)
            const json = await response.json().then(data => {
                if (data.statusCode != 500) {
                    console.log(data)
                    //   isRegistered()
                    skelbimus()
                    alert(t('skelbimas_atnaujintas'))
                    if (Object.keys(now).length == 0) {
                        window.location.reload()
                    }
                }
                //         return
            })
        } catch (error) {
            console.error(error);
        }
    };

    const gather = (item, vertimas) => {
        let splited = item.split(',')
        let arr = []
        splited.forEach(element => {
            if (!isNaN(element) && element) {
                arr.push({
                    value: element,
                    label: vertimas[element]
                })
            }

        });
        return arr
    }
    const gatherK = (item, vertimas) => {
        let splited = item.split(',')
        let arr = []
        splited.forEach(element => {
            element = element.split(':')
            if (element != '') {
                arr.push({
                    value: element[1],
                    label: vertimas[element[0]]
                })
                setPasirinktasLygis(prevState => ({
                    pasirinktasLygis: {
                        ...prevState.pasirinktasLygis,
                        [vertimas[element[0]]]: element[1]
                    }
                }))
            }
        });
        return arr
    }
    // maybe bottom

    useEffect(() => {
        if (Object.keys(now).length > 0) {
            putData(now)
        } else {
            clear()
        }
    }, [now])

    const [pareigos, setPareigos] = useState('')
    const [sritysD, setsritysD] = useState('')
    const [darboP, setDarboP] = useState('')
    const [sutartis, setSutartis] = useState('')
    const [etatas, setEtatas] = useState('')
    const [currentKalba, setCurrentKalba] = useState('')
    const [kita_kalba, setKita_kalba] = useState('')
    const [miestas, setMiestas] = useState('')
    const [checked, setChecked] = useState(false)
    const [checked2, setChecked2] = useState(false)
    const [checked4, setChecked4] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [cv, setCv] = useState(false)
    const [linkedin, setLinkedin] = useState(false)
    const [nuo, setNuo] = useState('')
    const [iki, setIki] = useState('')
    const [darboA, setDarboA] = useState('')
    const [klausimai, setKlausimai] = useState('')
    const [kontaktinis, setKontaktinis] = useState('')
    const [date, setDate] = useState('')
    const [pasirinktasLygis, setPasirinktasLygis] = useState({})
    const [kalba, setKalba] = useState([])

    const [vezimeliai, setVezimeliai] = useState(false)
    const [atvirumo_tekstas, setAtvirumo_tekstas] = useState(false)
    const [komforto, setKomforto] = useState(false)
    const [non_crash, setNon_crash] = useState('')
    const putData = (item) => {
        console.log(item)
        setDarboP(gather(item.darbo_pozicija, t('darbo_pozicijos')))
        setsritysD(gather(item.darbo_sritis, t('darbo_sritis')))
        setSutartis(gather(item.sutarties_pobudis, t('sutarties_pobudis')))
        setEtatas(gather(item.etatas, t('etatas')))
        setKalba(gatherK(item.kalbos_ir_ju_lygiai, t('kalbos')))
        setMiestas(gather(item.miestas, t('miestai')))
        setPareigos(item.pareigos)
        setKontaktinis(item.kontaktinis_el_pastas)
        setKita_kalba(item.kita_kalba ? item.kita_kalba : '')
        if (item.miestas.indexOf('visi_miestai') > -1) { setChecked(true) }
        if (item.miestas.indexOf('nuotoliniu') > -1) { setChecked2(true) }
        if (item.miestas.indexOf('hibridinis') > -1) { setChecked4(true) }
        setNuo(item.atlygis.split('-')[0])
        setIki(item.atlygis.split('-')[1])
        setDarboA(item.darbo_aprasymas)
        setKlausimai(item.papildomi_klausimai)
        setLinkedin(item.is_Linkedin)
        setAtvirumo_tekstas(item.atvirumo_tekstas)
        setVezimeliai(item.pritaikyta_judeti_vezimelyje)
        setKomforto(item.reguliarios_komforto_pertraukos)
        setCv(item.is_CV)
        setDate(new Date(item.galiojimo_laikas))
        // setNon_crash(item.galiojimo_laikas.split('T')[0])
    }
    const clear = () => {
        setDarboP('')
        setsritysD('')
        setSutartis('')
        setEtatas('')
        setKalba('')
        setMiestas('')
        setPareigos('')
        setKontaktinis('')
        setKita_kalba('')
        setChecked(false)
        setChecked2(false)
        setChecked4(false)
        setNuo('')
        setIki('')
        setDarboA('')
        setKlausimai('')
        setLinkedin(false)
        setCv(false)
        setDate('')
        setCurrentKalba('')
        setPasirinktasLygis('')
        setNon_crash('')
        setAtvirumo_tekstas(false)
        setVezimeliai(false)
        setKomforto(false)
    }

    return (
        <div className="mainSkelbimai">
            <div className="leftSkelbimai">
                {
                    // console.log(skelbimai)
                    skelbimai[0] == 'empty' ? <div>loading</div> : (
                        <div className="adContainer"><h2>{t('skelbimai')}</h2>
                            <div className="adsai">
                                {skelbimai.length > 0 ? (

                                    <DarbdavioSkelbimasGoTo t={((value => { t(value) }))} change={(value) => { setNow(value) }} s={skelbimai} redo={() => { skelbimus() }} createNew={(value) => {
                                        dublikuoti(value)
                                    }} />
                                ) : ('')}

                                <EmptyAd label={t('Prideti_Skelbima')}
                                    change={(value) => { setNow(value) }} />
                            </div>
                        </div>

                    )
                }

            </div>
            <div className="rightSkelbimai">
                <form onSubmit={(e) => {
                    create()
                    e.preventDefault()
                }}>
                    <div>
                        <div className=" centerContainer">
                            <div className="rightContainer">
                                <ContentCopyIcon style={{ color: 'white' }} className="duplicate" onClick={() => {
                                    dublikuoti(true) //setModalDublicate(true);
                                }}
                                />
                                <h2>{pareigos == '' ? t('Prideti_Skelbima') : pareigos}</h2>
                                <div className="forma">
                                    <div className="checkIt">
                                        <Select
                                            required
                                            placeholder={t('darbdavio_top_steps')[0]}
                                            className="select"
                                            isMulti={true}
                                            value={sritysD}
                                            onChange={setsritysD}
                                            options={sritys}
                                        />
                                        <input
                                            className="hiddenCheck"
                                            onChange={(e) => { e.preventDefault() }}
                                            required
                                            value={sritysD} />
                                    </div>
                                    <input
                                        // placeholder={t('darbdavio_top_steps')[1]}
                                        required
                                        className="input"
                                        placeholder={t('darbo_pareigos')}
                                        value={pareigos}
                                        onChange={(e) => { setPareigos(e.target.value) }}
                                    />
                                    <div className="checkIt">
                                        <Select
                                            placeholder={t('darbdavio_top_steps')[2]}
                                            className="select"
                                            isMulti={true}
                                            value={darboP}
                                            onChange={setDarboP}
                                            options={darboPozicija}
                                        />
                                        <input
                                            className="hiddenCheck"
                                            onChange={(e) => { e.preventDefault() }}
                                            required
                                            value={darboP} />
                                    </div>
                                    <div className="checkIt">
                                        <Select
                                            placeholder={t('darbdavio_top_Pozicija')[2]}
                                            className="select"
                                            isMulti={true}
                                            value={sutartis}
                                            onChange={setSutartis}
                                            options={sutartisD}
                                        />
                                        <input
                                            className="hiddenCheck"
                                            onChange={(e) => { e.preventDefault() }}
                                            required
                                            value={sutartis} />
                                    </div>
                                    <div className="checkIt">
                                        <Select
                                            placeholder={t('darbdavio_top_Sutarties')[2]}
                                            className="select"
                                            isMulti={true}
                                            value={etatas}
                                            onChange={setEtatas}
                                            options={etatasO}
                                        />
                                        <input
                                            className="hiddenCheck"
                                            onChange={(e) => { e.preventDefault() }}
                                            required
                                            value={etatas} />
                                    </div>
                                    <div className="checkIt">
                                        <Select
                                            placeholder={t('darbdavio_top_Miestas')[0]}
                                            className="select"
                                            isMulti={true}
                                            value={kalba}
                                            onChange={(value) => {
                                                if (value.length >= kalba.length) {
                                                    setModalIsOpen(true)
                                                    setCurrentKalba(value[value.length - 1])
                                                }
                                                // if (kalba.indexOf(value) == 0) {
                                                setKalba(value)
                                                // }
                                                console.log('kalba', kalba)
                                                console.log('value', value)
                                            }}
                                            options={kalbaO}
                                        />
                                        <input
                                            className="hiddenCheck"
                                            onChange={(e) => { e.preventDefault() }}
                                            required
                                            value={kalba} />
                                    </div>
                                    <input
                                        className="input"
                                        type={'text'}
                                        value={kita_kalba}
                                        placeholder={t('kita') + ' ' + t('kalba')}
                                        onChange={(e) => { setKita_kalba(e.target.value) }}
                                    />
                                    <div className="checkIt">
                                        <Select
                                            placeholder={t('darbdavio_top_Miestas')[1]}
                                            className="select"
                                            isMulti={true}
                                            value={miestas}
                                            onChange={setMiestas}
                                            options={miestasO}
                                        />
                                        <input
                                            className="hiddenCheck"
                                            onChange={(e) => { e.preventDefault() }}
                                            required
                                            value={miestas + checked2 ? '1' : '' + checked ? '2' : ''} />
                                    </div>
                                    <div className="flex column">
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={checked}
                                                onChange={() => {
                                                    setChecked(!checked)
                                                }}
                                            />
                                            {t('visi_miestai')}
                                        </label>
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={checked2}
                                                onChange={() => {
                                                    setChecked2(!checked2)
                                                }}
                                            />
                                            {t('nuotoliniu')}
                                        </label>
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={checked4}
                                                onChange={() => {
                                                    setChecked4(!checked4)
                                                }}
                                            />
                                            {t('hibridinis_darbas')}
                                        </label>
                                    </div>
                                    <h3 className="columnName">{t('atlyginimas')}</h3>
                                    <input
                                        type="number"
                                        className="input"
                                        placeholder={t('nuo')}
                                        value={nuo}
                                        onChange={(e) => { setNuo(e.target.value) }}
                                    />
                                    <input
                                        type="number"
                                        className="input"
                                        placeholder={t('iki')}
                                        value={iki}
                                        onChange={(e) => { setIki(e.target.value) }}
                                    />
                                    <h3 className="columnName">{t('darbdavio_top_Atlyginimas')[2]}</h3>
                                    <textarea
                                        type="textarea"
                                        className="textarea"
                                        value={darboA}
                                        onChange={(e) => { setDarboA(e.target.value) }}
                                    />
                                    <input type="email"
                                        required
                                        className="input"
                                        placeholder={t('kontaktinis')}
                                        value={kontaktinis}
                                        onChange={(e) => { setKontaktinis(e.target.value) }}
                                    />
                                    <h3 className="columnName">{t('darbdavio_top_SkelbimoLaikas')[1]}</h3>
                                    <textarea
                                        type="textarea"
                                        className="textarea"
                                        value={klausimai}
                                        onChange={(e) => { setKlausimai(e.target.value) }}
                                    />
                                    <div className="flex column">
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={cv}
                                                onChange={() => {
                                                    setCv(!cv)
                                                }}
                                            />
                                            {t('reikalingas_CV')}
                                        </label>
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={linkedin}
                                                onChange={() => {
                                                    setLinkedin(!linkedin)
                                                }}
                                            />
                                            {t('reikalingas_Linkedin')}
                                        </label>
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={komforto}
                                                onChange={() => {
                                                    setKomforto(!komforto)
                                                }}
                                            />
                                            {t('reguliarios_komforto_pertraukos')}
                                        </label>
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={vezimeliai}
                                                onChange={() => {
                                                    setVezimeliai(!vezimeliai)
                                                }}
                                            />
                                            {t('pritaikyta_judeti_vezimelyje')}
                                        </label>
                                        <label className="checkBox">
                                            <input
                                                type={'checkbox'}
                                                checked={atvirumo_tekstas}
                                                onChange={() => {
                                                    setAtvirumo_tekstas(!atvirumo_tekstas)
                                                }}
                                            />
                                            {t('norite_teskstas_atsirastu')}
                                            <span> "{t('atviri_poreikiams')}"</span>
                                        </label>
                                    </div>
                                    <h3 className="columnName">{t('darbdavio_top_SkelbimoLaikas')[2]}</h3>
                                    <div className="dateMargin">
                                        <div className="checkIt">
                                            <h3 className="columnName">{non_crash}</h3>
                                            <DatePicker
                                                dateFormat="dd/mm/yyyy"
                                                className={'input calenderOuter'}
                                                calendarClassName={'calenderInner'}
                                                value={date}
                                                onChange={(data) => { setDate(data); console.log(data) }} />
                                            <input
                                                className="hiddenCheck"
                                                onChange={(e) => { e.preventDefault() }}
                                                required
                                                value={date} />
                                        </div>
                                    </div>
                                </div>
                                <button className="mainBtn selfCenter" onClick={(e) => {
                                    // console.log(e)

                                }}>
                                    {t('issaugoti')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        ariaHideApp={false}
                        className="modal"
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={() => { setModalIsOpen(false) }}
                    >
                        <div
                            className="centerContainer column">

                            <div className="closeBtn">
                                <button onClick={() => {
                                    setModalIsOpen(false)
                                    let last = document.querySelectorAll('[aria-label="Remove ' + currentKalba.label + '"]')
                                    last[0].click()
                                }}>✖</button>
                            </div>
                            <h3>{currentKalba.label}</h3>
                            <div
                                className="flex column radioContainer"
                                onChange={(e) => {
                                    setPasirinktasLygis(prevState => ({
                                        pasirinktasLygis: {
                                            ...prevState.pasirinktasLygis,
                                            [currentKalba.label]: e.target.defaultValue
                                        }
                                    }))
                                }}>
                                <div className="radioMargin">
                                    <label><input type="radio" value={0} name="kalbos_lygiai" /> {kalbosLygiai[0]}</label>
                                </div>
                                <div className="radioMargin">
                                    <label><input type="radio" value={1} name="kalbos_lygiai" /> {kalbosLygiai[1]}</label>
                                </div>
                                <div className="radioMargin">
                                    <label><input type="radio" value={2} name="kalbos_lygiai" /> {kalbosLygiai[2]}</label>
                                </div>
                                <div className="radioMargin">
                                    <label><input type="radio" value={3} name="kalbos_lygiai" /> {kalbosLygiai[3]}</label>
                                </div>
                            </div>
                            <button
                                onClick={() => {
                                    setModalIsOpen(false)
                                    console.log(pasirinktasLygis, kalba)

                                }}
                                className="mainBtn modalBtn">{t('issaugoti')}</button>
                        </div>

                    </Modal>



                </form >
            </div>
        </div>

    );
}
const DarbdavioSkelbimasGoTo = (props) => {
    const [modalIsOpen2, setModalIsOpen2] = useState(false)
    const [modalDublicate, setModalDublicate] = useState(false)
    const [info, setInfo] = useState(false)
    const { t } = useTranslation()
    const { routas } = useContext(TaskContext)

    let all = props.s
    console.log(all[0].is_visible)


    let mad = all.map((element, i) => (
        <div onClick={() => {
            props.change(element)
        }} className="adsList3" key={i}>{element.pareigos}
            <div className="switch">
                <IOSSwitch
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    // checked={change?'checked':'unchecked'}
                    checked={element.is_visible}
                    onChange={() => {
                        update(element)
                    }}
                />

                <div onClick={() => { setModalIsOpen2(true); setInfo(element) }} className="delete">✖
                </div>

            </div>

        </div>



    ))
    const update = async (all) => {
        try {
            let requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body:
                    JSON.stringify({
                        is_visible: !all.is_visible
                    })
            };

            let create_listing = routas + 'skelbimai/' + all._id + '';
            const response = await fetch(create_listing, requestOptions)
            const json = await response.json().then(data => {
                if (data.statusCode != 500) {
                    console.log(data)
                    props.redo()

                }
            })
        } catch (error) {
            console.error(error);
        }
    };
    const istrint = async (all) => {
        console.log(all)
        try {

            let deleteRoute = routas + 'skelbimai/' + all._id;
            let trinti = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            }

            const response = await fetch(deleteRoute, trinti)
            props.redo()
        } catch (error) {
            console.error(error);
        }


    }

    return (

        < div >
            {mad}

            <Modal
                isOpen={modalDublicate}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalDublicate(false) }}
            >
                <div
                    className="centerContainer1 column">

                    <div className="closeBtn">
                        <button onClick={() => {
                            setModalDublicate(false)
                        }}>✖</button>
                    </div>

                    <div className="modalInfo" >
                        <h4>{t('dublikuoti')}?</h4>
                    </div>
                    <div className={"modalBtnContainer"}  >

                        <button
                            onClick={() => { console.log('?'); }}// create(info);
                            className=" modalBtn1">{t('taip')}</button>
                        <button
                            onClick={() => { setModalDublicate(false) }}
                            className=" modalBtn1">{t('ne')}</button>
                    </div>
                </div>

            </Modal>
            <Modal
                isOpen={modalIsOpen2}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen2(false) }}
            >
                <div
                    className="centerContainer1 column">

                    <div className="closeBtn">
                        <button onClick={() => {
                            setModalIsOpen2(false)
                        }}>✖</button>
                    </div>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_istrinti')}?</h4>
                    </div>
                    <div className={"modalBtnContainer"}  >

                        <button
                            onClick={() => { istrint(info); setModalIsOpen2(false) }}
                            className=" modalBtn1">{t('taip')}</button>
                        <button
                            onClick={() => { setModalIsOpen2(false) }}
                            className=" modalBtn1">{t('ne')}</button>
                    </div>
                </div>

            </Modal>
        </ div>
    )
}
const EmptyAd = (props) => {
    return (<div onClick={() => {
        props.change({})
    }} className="titleProps" >+  {props.label}</div>)
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#1c2f5d' : '#1c2f5d',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#1c2f5d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));