
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TaskContext } from "./AppStates";
import Modal from 'react-modal';


import Logo from "./logo.svg";
const Navigation = () => {
    const { t, i18n } = useTranslation();
    const { user, setTasks, routas } = useContext(TaskContext)

    const changeLanguage = (lng) => {
        try {
            if (lng != currentLng)
                i18n.changeLanguage(lng);
            window.location.reload()
        }
        catch (e) { console.log(e) }
    }
    const kalbos = t('languages')
    const [currentLng, setCurrentLng] = useState(i18n.language)

    let body = document.getElementsByTagName('body')
    document.body.classList.add(currentLng);
    const LogOut = async () => {
        let Logout = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
        }
        let logoutpath = routas + 'users/logout/' + user.access_token;
        try {
            const response = await fetch(logoutpath, Logout)
            const json = await response.json().then(data => {
                console.log(data)
                setTasks('login')
                window.location='/' 
            })
        } catch (error) {
            console.error(error);
        }
    }

    const { tasks } = useContext(TaskContext)

    const [modalIsOpen, setModalIsOpen] = useState(false)
    // const handleClose = () => setModalIsOpen(false);
    const handleShow = () => setModalIsOpen(true);
    const [info, setInfo] = useState({})

    return (
        <div className="navBar">
            <div className="navLogo" onClick={() => {
                if(currentLng!='en'){
                    window.location.replace("https://scobo.lt/"+currentLng+'/')
                }else{
                    window.location.replace("https://scobo.lt/")
                }
                }}>
                <img src={Logo} alt="Logo" />
               
            </div>
            {tasks == 'login' ? (<div key='epty one'>
         

            </div>) : tasks =='reg'?(<div>
                <nav key='navigationConainer' className="navContainer" >
                    <a key={t('atsijungti')} className="navItem1"  onClick={() => { handleShow(); setInfo(info);}}>{t('atsijungti')}</a>
                </nav>
            </div>): (
                <nav key='navigationConainer' className="navContainer" >
                    <Link key={t('siulomi_darbuotojai')} className="navItem" to="/">{t('siulomi_darbuotojai')}</Link>
                    <Link key={t('issaugoti_darbuotojai')} className="navItem" to="/saved">{t('issaugoti_darbuotojai')}</Link>
                    <Link key={t('darbuotoju_baze')} className="navItem" to="/db">{t('darbuotoju_baze')}</Link>
                    <Link key={t('skelbimai')} className="navItem" to="/ads">{t('skelbimai')}</Link>
                    <Link key={t('Profilis')} className="navItem" to="/profile">{t('Profilis')}</Link>
                    <a key={t('atsijungti')} className="navItem1"  onClick={() => { handleShow(); setInfo(info);}}>{t('atsijungti')}</a>
                </nav>
            )}
            <div className="languageSelector">
                <div key="currentLng" className="currentLng">{currentLng}</div>
                <div key="changeLng" className="changeLng">
                    {kalbos.map((e,i) => (
                        <div>
                            {e != currentLng ? <div key={"lang-" + e} onClick={() => {
                                changeLanguage(e)
                            }} className="language" >{e}</div> : <div key={'epmty'}></div>}

                        </div>
                    ))}
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={ ()=>{setModalIsOpen(false)}}
            >
                <div
                    className="centerContainer1 column">

                    <div className="closeBtn">
                        <button onClick={() => {
                            setModalIsOpen(false)
                            // let last = document.querySelectorAll('[aria-label="Remove ' + currentKalba.label + '"]')
                            // last[0].click()
                            
                        }}>✖</button>
                    </div>
                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_atsijungti')}?</h4>
                    </div>
                    <div className={"modalBtnContainer"}  >

                        <button
                            onClick={() => { LogOut(); setTasks('login'); setModalIsOpen(false) }}
                            className=" modalBtn1">{t('taip')}</button>
                    </div>
                </div>

            </Modal>
        </div>

    )
}

export default Navigation