import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskContext } from "../AppStates";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import GradeIcon from '@mui/icons-material/Grade';
import ReactDOMServer from 'react-dom/server';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import lootiegif from "../assets/logoLottie.json";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
export default function DB() {
  const { routas, user } = useContext(TaskContext)
  const { t } = useTranslation()
  const [skelbimai, setSkelbimai] = useState(['empty'])





  const [columns, setColums] = useState([
    // {
    //   field: 'save',
    //   headerName: t('issaugoti'),
    //   width: 150,
    //   // ***
    //   renderCell: renderDetailsButton,
    //   // ***
    //   disableClickEventBubbling: true,
    // },
    // {
    //   field: 'savea',
    //   headerName: t('issaugoti'),
    //   width: 150,
    // },
    { field: 'vardas', headerName: t('vardas_filtras'), width: 150 },
    { field: 'pavarde', headerName: t('pavarde_filtras'), width: 150 },
    { field: 'miestas', headerName: t('miestas'), width: 150, },
    { field: 'kontaktinis_el_pastas', headerName: t('kontaktinis_el'), width: 200 },
    { field: 'kontaktinis_tel_nr', headerName: t('tel'), width: 200 },
    { field: 'patirtis', headerName: t('darbuotojo_options_top')[3], width: 150 },
    { field: 'sutarties_pobudis', headerName: t('darbuotojo_options_top')[4], width: 200 },
    { field: 'issilavinimas', headerName: t('darbuotojo_options_top')[0], width: 200 },
    { field: 'studiju_sritis', headerName: t('darbuotojo_options_top')[1], width: 200 },
    { field: 'atlygis', headerName: t('darbuotojo_options_top')[8], width: 200 },
    { field: 'darbo_sritis', headerName: t('darbuotojo_options_top')[2], width: 200 },
    { field: 'kalbos_ir_ju_lygiai', headerName: t('darbuotojo_options_top')[6], width: 200 },
    // { field: 'issilavinimas', headerName: t('issilavinimas'),width:200},
  ])
  const [rows, setRows] = useState([
    { id: 1, vardas: '' }

  ]);
  const [data, setData] = useState({
    columns: columns,
    rows: rows,
    rowLength: 10,
    maxColumns: 5,
  })


  useEffect(async () => {
    try {
      if (user != {}) {
        getDb(routas, user, setSkelbimai).then((data) => {
        })
      }
    }
    catch (e) { console.log(e) }
  }, [user])

  const getTranslation = (vertimas, values) => {
    let html = ''
    if (values != '' && values) {
      values = values.split(',')
      values.forEach(element => {
        if (!isNaN(element) && vertimas[element]) {
          html += vertimas[element] + ', '
        }
        else { html += t(values) + ', ' }
      });
      html = html.substring(0, html.length - 2);
      html = html.replace(', undefined', '')
      html = html.split(',').filter(function (el) { return el != '' }).join(',').replace(', ,', ', ');
      return html
    }
  }
  const getTranslationK = (vertimas, vertimas2, values, K) => {
    let html = ''
    if (values != '' && values) {
      values = values.split(',')
      values.forEach(element => {
        if (element != '') {
          element = element.split(':')
          html += vertimas[element[0]] + ': ' + vertimas2[element[1]] + ', '
        }
        // else{html+=t(values)+', '}
      });
      if (K != '' && K) { html += K + ', ' }
      html = html.substring(0, html.length - 2);
      html = html.replace('undefined', '')
      html = html.split(',').filter(function (el) { return el != '' }).join(',').replace(', ,', ', ');


      return html
    }
  }
  const issaugoti = async (mano, darbuotojo) => {
    const get_listing = routas + 'users/save/' + mano + '/' + darbuotojo;
    const get_skelbimai = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
    }

    try {
      const response = await fetch(get_listing, get_skelbimai)
    } catch (error) {
      console.error(error);
    }
  };

  const save = <button>{t('issaugoti')}</button>
  const remove = () => { return <button>{t('istrinti_issaugota')}</button> }
  const getIsSaved = (id) => {
    // console.log(user, 'get')
    if (user.issaugoti_darbuotojai.indexOf(id) > -1) {
      // console.log(remove)
      return 'remove'
    }
    return ReactDOMServer.renderToStaticMarkup(<Tooltip title={t('issaugoti')}>
      <IconButton ref={'id-btn-save'} onClick={(e) => {
        e.stopPropagation()
        issaugoti(user._id, id)
      }}
        disableRipple={true}
        /* You wouldn't use a tooltip when hover styling is disabled*/
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
            cursor: "default"
          }
          //"&:hover": { color: "green" }
        }}
      >
        <GradeIcon />
      </IconButton>
    </Tooltip>)
  }
  // console.log(user)  
  useEffect(() => {
    let s = skelbimai
    // console.log(s)
    if (s[0] != 'empty') {
      try {

        s.forEach((element, i) => {
          // console.log(getIsSaved(s[i].id))
          // s[i].save = user.issaugoti_darbuotojai.indexOf(s[i]._id) > -1 ? getIsSaved(s[i].id) : 'remove()'
          s[i].id = s[i]._id
          s[i].miestas = getTranslation(t('miestai'), s[i].miestas)
          s[i].patirtis = getTranslation(t('patirtis_metais'), s[i].patirtis)
          s[i].sutarties_pobudis = getTranslation(t('sutarties_pobudis'), s[i].sutarties_pobudis)
          s[i].issilavinimas = getTranslation(t('issilavinimas'), s[i].issilavinimas)
          s[i].studiju_sritis = getTranslation(t('sritys'), s[i].studiju_sritis)
          s[i].darbo_sritis = getTranslation(t('darbo_sritis'), s[i].darbo_sritis)
          s[i].kalbos_ir_ju_lygiai = getTranslationK(t('kalbos'), t('kalbos_lygiai'), s[i].kalbos_ir_ju_lygiai, s[i].kita_kalba)
          //  s[i].key=s[i]._id
          
        });
        setRows(s)
      } catch (e) { console.log(e) }
    }
  }, [skelbimai, user])

  useEffect(() => {
    setData({
      columns: columns,
      rows: rows,
      rowLength: 10,
      maxColumns: 5,
    })
  }, [rows])
  return (
    <main style={{ padding: "1rem 0" }}>
      <h2>{t('darbuotoju_baze')}</h2>
      {skelbimai[0] != 'empty' && data[0] != 'empty' ? (
        <div className="dbMain" style={{ height: '600px', width: '97%' }}>
          <DataGrid
            className="dbData"
            {...data}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      ) : (
        <div className="loader">
          <Player
            autoplay
            loop
            src={lootiegif}
            style={{ height: '150px', width: '150px' }}
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </div>
      )}
    </main>
  );
}



const getDb = async (routas, naudotojas, setSkelbimai, addData) => {
  let get_listing = routas + 'users/db/' + naudotojas._id;

  let get_skelbimai = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }

  if (naudotojas)
    try {
      const response = await fetch(get_listing, get_skelbimai)
      const json = await response.json().then(data => {
        setSkelbimai(data)
        return data
      })
    } catch (error) {
      console.error(error);
    }
};
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

const renderDetailsButton = () => {
  return (
    <strong>
      <button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          console.log('')
        }}
      >
        More Info
      </button>
    </strong>
  )
}