import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskContext } from "../AppStates";
import Modal from 'react-modal';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import { gautiSkelbimus, poke, selectorius,Langelis, istrint,kalbos,perKalbleli } from "../functions/extraF";



export default function Kandidatai() {
  const { user, routas } = useContext(TaskContext)
  const [data, setData] = useState({})
  const [manoSkelbimai, setManoSkelbimai] = useState([])
  const [modalIsOpen2, setModalIsOpen2] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [pav, setPav] = useState('')

  const { t } = useTranslation()
  const getCard = async () => {
    let cardDomain = routas + 'users/issaugoti/' + user._id;
    let requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(cardDomain, requestOptions)
      .then(async response => {
        await response.json().then(data => {
          if (data.statusCode != 500 && data != []) {
            setData(data)
            console.log(data)
          }
        })
      })
      .catch(error => {
        console.error('There was an error!', error);
        return false
      });
  }
  useEffect(() => {
    getCard()
  }, [])
 

  const [modalIsOpen, setModalIsOpen] = useState(false)
  // const handleClose = () => setModalIsOpen(false);
  const handleShow = () => setModalIsOpen(true);

  const [info, setInfo] = useState({})
  const kortele = (item, i) => {
    return (
      <div key={i} className="kortele" onClick={() => { handleShow(); setInfo(item) }} >
        <div className="close"onClick={(e) => {
            e.stopPropagation()
          }}></div>
        <div className="cardInfo">
          <div className="candidateInfo" ><h4>{item.vardas} {item.pavarde}</h4></div>
          <div className="candidateInfo1"><h6>{perKalbleli(item.darbo_sritis, t('darbo_sritis'))}</h6></div>
        </div>
        <div className="smallBtnContainer">
        <Tooltip title={t('poke')}>
              <IconButton
              
               onClick={(e) => {
                e.stopPropagation()
                gautiSkelbimus(routas, user, setManoSkelbimai)
                setInfo(item)
                setModalIsOpen2(true)
                setSelectedId('')
              }} 
               disableRipple={true}
               /* You wouldn't use a tooltip when hover styling is disabled*/
               sx={{
                 "&:hover": {
                   backgroundColor: "transparent",
                   cursor: "default"
                 }
                 //"&:hover": { color: "green" }
               }}>
                <NotificationsIcon
                  // onClick={(e) => {
                  //   e.stopPropagation()
                  // }} 
                  />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('istrinti_issaugota')}>
           <IconButton onClick={(e) => {
              e.stopPropagation()
              istrint(routas, user._id, item._id).then(()=>{getCard()})
            }} 
            disableRipple={true}
               /* You wouldn't use a tooltip when hover styling is disabled*/
               sx={{
                 "&:hover": {
                   backgroundColor: "transparent",
                   cursor: "default"
                 }
                 //"&:hover": { color: "green" }
               }}
            >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>

         
        </div>
      </div>
    )
  }
  return (
    <main style={{ padding: "1rem 0" }}>
      {data.length > 0 ? (
        <div>
          <h2>{t('issaugoti_darbuotojai')}</h2>

          <div className="korteles">
            {data.map((e, i) => (
              kortele(e, i)
            ))}
          </div>
        </div>
      ) : (
        <h2>{t('nera_issaugotos')}</h2>
      )}

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        className="modal1"
        shouldCloseOnOverlayClick={true}
                onRequestClose={ ()=>{setModalIsOpen(false)}}
      >
        <div
          className="centerContainer1 column">

          <div className="closeBtn">
            <button onClick={() => {
              setModalIsOpen(false)
              // let last = document.querySelectorAll('[aria-label="Remove ' + currentKalba.label + '"]')
              // last[0].click()
            }}>✖</button>
          </div>
          <h3>{info.vardas} {info.pavarde}</h3>
          <div className="modalInfo" >
            <div
              className="flex column radioContainer1"
            >
              {Langelis(info.studiju_sritis,t('darbuotojo_options_top')[0], t('issilavinimas')[info.issilavinimas] + ' | ' + perKalbleli(info.studiju_sritis, t('sritys')))}
              {Langelis(info.patirtis,t('darbuotojo_options_top')[3], t('patirtis_metais')[info.patirtis] + ' ' + t('metai') + ' ' + ' | ' + perKalbleli(info.darbo_sritis, t('darbo_sritis')))}
              {Langelis(info.kalbos_ir_ju_lygiai,t('darbuotojo_options_top')[6], kalbos(info.kalbos_ir_ju_lygiai,t))}
              {Langelis(info.etatas,t('poreikis'), t('darbuotojo_options_top')[5] + ':' + perKalbleli(info.etatas, t('etatas')) + info.atlygis)}
              {Langelis(info.sutarties_pobudis,t('darbuotojo_options_top')[4], perKalbleli(info.sutarties_pobudis, t('sutarties_pobudis')))}
              {Langelis(info.aprasymas,t('darbuotojo_options_top')[9], info.aprasymas)}

            </div>
          </div>
          <button
            onClick={() => {
              setModalIsOpen(false)
            }}
            className="mainBtn modalBtn">{t('uzdaryti')}</button>
        </div>

      </Modal>

      <Modal
        isOpen={modalIsOpen2}
        ariaHideApp={false}
        className="modal4"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => { setModalIsOpen2(false) }}
      >
        <div className="centerContainer1 column">
          <div className="closeBtn">
            <button onClick={() => {
              setModalIsOpen2(false)
            }}>✖</button>
          </div>
          <div className="" >
            <div className="flex column radioContainer">
              {selectorius(manoSkelbimai, (value) => { setSelectedId(value) }, t('skelbimai'), pav, (value) => { setPav(value) })}
            </div>
          </div>
          <button
            onClick={(e) => {
              setModalIsOpen2(false)
              console.log(manoSkelbimai)
              poke(routas, selectedId.value, info._id)
            }}
            className={selectedId == "" ? "disabled mainBtn modalBtn" : "mainBtn modalBtn"}>{t('poke')}</button>
        </div>
      </Modal>
    </main>



  );
}