export const ar = {

    translations: {
        welcome: "مرحبا",
        toliau: "التالى",
        pradeti: "البداية",
        ar_darbdavys_darbuotojas_text: "هل انت صاحب عمل او باحث عن عمل ",
        ieskau_Darbo: "انا ابحث عن عمل",
        esu_darbdavys: "انا صاحب عمل",
        Prisijungimas: "تسجيل الدخول",
        Registracija: "تسجيل  ",
        Registruotis: "للتسجيل",
        google: "جوجل",
        facebook: "فيسبوك",
        arba_registruokis: "او قم بالتسجيل",
        email: "بريد الكترونى",
        atgal: "العودة",
        praleisti: "تخطى",
        Prie_Scovo: "للاتصال بscobo",
        prisijungimas: "تسجيل الدخول",
        registracija: "التسجيل",
        slaptazodis: "كلمة المرور",
        pamirsauSlaptazodi: "نسيت كلمة المرور",
        Prisijungti: "تسجيل الدخول",
        Pakartoti_slaptazodis: "اعادة كلمة المرور",
        Vardas: "الاسم",
        Pavarde: "اللقب",
        Labas: "مرحبا!",
        Laukiame: "نحن فى انتظار انضمامك",
        Pasirinkite_issilavinimo_lygi: "حدد مستوى التعليم",
        ar_turite_patirties: "هل لديك خبرة فى هذا المجال",
        taip: "نعم",
        ne: "لا",
        kiek_metu: "ما هى عدد سنوات خبرتك فى هذا المجال ",
        visi_miestai: "كل المدن",
        nuotoliniu: "العمل عن بعد",
        hibridinis_darbas: "العمل الهجين",
        nuo: "من",
        iki: "الى ",
        darbo_aprasymas: "الوصف الوظيفى و المتطلبات ",
        Aprasymas: "الوصف",
        kontaktinis_el: "للتواصل بالبريد الالكترونى ",
        tel: "رقم الهاتف ",
        kotaktu_sutikimas: "اوافق ان يقوم  صاحب العمل بالاتصال بى عبر البيانات المتاحة ",
        aprasymas_apie_save: "ارفق وصف مختصر عن نفسك ",
        uzpildyti_apie_imone: "املأ المعلومات الخاصة بالشركة ",
        imones_pavadinimas: "اسم الشركة ",
        apie_imone: "عن الشركة ",
        svetaine: "رابط للموقع الالكترونى ",
        darbuotoju_sk: "عدد العاملين ",
        date: "يوم/شهر/سنة ",
        teisineForma: "الهيكل القانونى للشركة ",
        veiklos_sritys: "مجال النشاط ",
        pridekite_darbo_pasiulyma: "ارفق عرض عمل ",
        pradekite_naudotis: "ارفق العرض و ابدأباستخدام !Scobo",
        prideti: "ارفق ",
        darbo_pareigos: "املاءالبيانات الوظيفية ",
        grizti: "رجوع ",
        klausimas: "سؤال ",
        reikalingas_CV: "هل تحتاج السيرة الذاتية للموظفين ",
        reikalingas_Linkedin: "هل تحتاج رابط لLinkedIn",
        Profilis: "الملف الشخصى",
        Sutape: "متطابقة",
        istrinti: "حذف الملف الشخصى",
        SutapoteSu: "تطابق مع",
        Susisiekti: "للتواصل",
        atsijungti: "خروج",
        asmenine_informacija: "معلومات شخصية",
        skelbimo_informacija: "معلومات الملف الشخصى",
        slaptazodzio_keitimas: "تغيير كلمة المرور",
        pagalba: "المساعدة",
        pranesimai: "إشعارات",
        issaugoti: "حفظ",
        sena_slaptazodi: "أدخل كلمة مرور قديمة",
        nauja_slaptazodi: "أدخل كلمة المرور الجديدة",
        nauja_pakartoti_slaptazodi: "اعادة كلمة المرور الجديدة",
        Prideti_Skelbima: "ارفق الاعلان الجديد",
        isikurimo_data: "تاريخ التأسيس",
        Sutape_darbuotojai: "الموظفين المطابقين",
        miestas: "المدينة",
        atlyginimas: 'الراتب الكلى ',
        aprasymas: "وصف",
        sritis: "مجال",
        daugiau: "اكثر",
        susiekite_elp: "بالبريد الالكترونى",
        susiekite_numeriu: "عبر الهاتف",
        turi_darbo_Skelbima: "لدية عرض عمل لك",
        Sutape_darbdaviai: "اصحاب الاعمال المتطابقين",
        kreipiantis: "للتواصل ,بالبريد الالكترونى  نحن نسأل",
        add_CV: "ارفق سيرة ذاتية",
        ir: "و",
        ats_kls: "أجب على هذة الاسئلة",
        kontaktinis: "تقديم عنوان البريد الالكترونى",
        darbuotoju_baze: "قاعدة الموظفين",
        issaugoti_darbuotojai: "الموظفين المحفوظين",
        darbo_sritis_filr: "مجال العمل",
        kalba_filtr: "اللغة",
        filtruoti: "تفعيل المرشحات",
        panaikinti_filtra: "حذف المرشحات",
        istrinti_issaugota: "حذف",
        metai: "عام ",
        nesutampa_psw: "كلمات السر غير متطابقة ",
        poke: "نكز",
        priregistruotas: "ستجد رابط التاكيد فى صندوق بريدك الالكترونى",
        praneskite_skelbima: "اخطار الموظف باعلان الوظيفة",
        poreikis: "بحاجة الى",
        ar_tikrai_atsijungti: "هل انت متأكد انك تريد الخروج",
        ar_tikrai_istrinti: "هل انت متأكد انك تريد حذف",
        uzdaryti: "اغلق",
        sutinku_prisijunimo: "اوافق على قواعد تسجيل الدخول",
        darbuotojo_aprasymas: "صف نفسك بأختصار",
        klausimai_kandidatam: "يمكنك طرح الاسئلة على المرشحين وسوف تحصل على اجباتهم من خلال استبياناتهم",
        Filtras: "البحث",
        istrinti_sutapusi: "حذف",
        darbuotojo_informacija: "معلومات الموظف ",
        filtras: "تنقية",
        skelbimai: "الاعلانات ",
        darbuotojai: "الموظفين",
        susipazinau_su_p: "لقد قرأت سياسة البيانات الشخصية ",
        Jau_priregistruotas: "البريد الالكترونى مسجل بالفعل ",
        blogai_ivesti_duomenys: "تم اخال بيانات غير صحيحة ",
        issaugoti_darbdaviai: "الموظفين المحفوظين",
        praneskite_skelbima_darbuotojas: "اخبار صاحب العمل عن نفسك ",
        Jus_issaugojo: "لقد تم حفظك بواسطة ",
        jumis_susidomejo: " مهتم بك",
        susi: "للأتصال ",
        darbuotoju_baze_sutikimas: "اوافق على عرض معلوماتى الشخصية  فى قاعدة البيانات الخاصة بأصحاب العمل",
        skelbimu_nera: "لا توجد إعلانات ",
        nera_jums_tinkanciu: "لا توجد إعلانات وظائف مناسبة مؤقتا،تحقق مرة اخرى قريبا!",
        siulomi_darbuotojai: "الموظفين المقترحين",
        siulomi_darbdaviai: "إعلانات الوظائف",
        pasirinkti_kalba: "اختر اللغة",
        studiju_sritis: "مجال الدراسة",
        issiusti: "إرسال",
        skelbimas_ijungtas: "الإعلان مرئى",
        skelbimas_isjungtas: "الاعلان غير مرئى",
        psw_visi_tokie_pat: "نفسة كما كان من قبل ",
        psw_pakeistas: "تم تغيير كلمة السر",
        psw_skirtingi: "كلمة المرور الجديدة غير متطابقة",
        psw_blogas: "كلمة سر خاطئة",
        susipazinau_darbdaviams: "اقر بأن جميع المعلومات التى اقدمها فى تطبيق SCOBO صحيحة وتتحمل الشركة المسؤولية الكاملة عن دقة المعلومات المقدمة وتوفير مكان عمل مناسب وفقا لتشريعات جمهورية لينوانيا",
        susipazinau_darbuotojams: "أقر بأنني أقوم بإنشاء حساب باسمي الخاص وأن جميع المعلومات التي أقدمها في تطبيق SCOBO صحيحة. أتحمل المسؤولية الكاملة عن دقة المعلومات المقدمة.",
        kita: "اخرى ",
        passikeisti_varda_pavarde: 'يرجى تحديث المعلومات الشخصية الخاصة بك ',
        duomenu_politika: 'سياسة البيانات',
        darbo_skelbimo: 'معلومات إعلان الوظيفة',
        skelbimas_atnaujintas: ' تم تحديث الإعلان بنجاح ',
        nera_issaugotos: "لم تقم بحفظ اى متقدم حتى الآن",
        svarbus_pakeitimai: "يوجد تغييرات مهمة ، ندعوك لتحديث التطبيق. بامكانك القيام بها هنا",
        atsisiusti: 'تحميل',
        pavarde_filtras: "اللقب",
        vardas_filtras: "الاسم",
        netinkantis: 'لا توجد اعلانات مناسبة لك حتى الآن، و لكن تحقق من هذه الإعلانات ',
        tikDarbdaviam: 'تسجيل الدخول للشركات',
        reguliarios_komforto_pertraukos: 'تتوفر فترات راحة منتظمة',
        pritaikyta_judeti_vezimelyje: 'يمكن الوصول إلى المبنى بواسطة الكراسي المتحركة',
        atviri_poreikiams: "نحن منفتحون على تكييف ظروف العمل مع الاحتياجات الفردية للموظفين",
        norite_teskstas_atsirastu: 'حدد المربع إذا كنت تريد أن يظهر هذا النص بجوار إعلانك',

        languages: [
            "en",
            "lt",
            "ru",
            "ar",
            "ua",
        ],
        languages_full: [
            "English",
            "Lietuvių",
            "Русский",
            "العربية",
            "Українська",
        ],
        darbuotojo_options_top: [
            "التعليم ",
            "مجال الدراسة ",
            "المجال المهنى ",
            "الخبرة",
            "نوع العقد ",
            "الساعات ",
            "اللغات ",
            "المدينة ",
            "الراتب ",
            "الوصف ",
            "جهات الاتصال ",
        ],
        issilavinimas: [
            "ابتدائى ",
            "اعدادى ",
            "ثانوى ",
            "مهنى ",
            "عالى ",
        ],
        sritys: [
            "الطب ",
            "طب الاسنان ",
            "الصيدلة ",
            "التغذية ",
            "إعادة التأهيل ",
            "التمريض ",
            "علوم الحياة ",
            "الزراعة و الطب البيطرى",
            " العلوم الطبيعية ",
            "الجيولوجيا ",
            "الأحياء ",
            "علم النباتات ",
            "علم الحيوان ",
            "علم الوراثة ",
            "الرياضة ",
            "الكمياء ",
            "الفيزياء ",
            "البيئة ",
            "الرياضيات و الحاسبات ",
            "الطهى ",
            "العلوم الاجتماعية ",
            "القانون ",
            "ادارة الاعمال ",
            "المالية ",
            "الحسابات ",
            "السياحة ",
            "علم النفس  ",
            "الاجتماع ",
            "العلوم السياسية ",
            "الاقتصاد ",
            "الاتصالات ",
            "التعليم و التدريب ",
            "فقة اللغة ",
            "الاداب ",
            "التاريخ ",
            "الفلسفة ",
            "علم اللاهوت ",
            "الدراسات الثقافية  ",
            "اخرى ",
        ],
        darbo_sritis: [
            "الزراعة ",
            "التحريج ",
            "مصايد الاسماك ",
            "التصنيع ",
            "الصناعة ",
            "البناء ",
            "البيع بالجملة و التجزئة ",
            "السياحة ",
            "الضيافة ",
            "تقديم الطعام ",
            "النقل ",
            "الخدمات اللوجيستية ",
            "العلاقات العامة ",
            "الاعلام ",
            "المالية ",
            "التأمين ",
            "العقارات ",
            "العلوم ",
            "الصيدلة ",
            "المنظمات ",
            "الادارة ",
            "المبيعات ",
            "الادارة العامة ",
            "تكنولوجيا المعلومات ",
            "التعيم ",
            "الرعاية الصحية ",
            "العمل الاجتماعى ",
            "الاداب ",
            " وسائل الترفية ",
            "الترفية ",
            "الرياضة ",
            "خدمة العملاء ",
            "الموارد البشرية ",
            "قطاع غير حكومى ",
            "اخرى ",
        ],
        patirtis_metais: [
            "1-3",
            "3-5",
            "5-10",
            "10 فأكثر",
        ],
        sutartis: [
            "عقد عمل ",
            "نشاط فردى ",
            "شهادة ادارة الاعمال ",
            "اخرى ",
            "اى نوع من العقود ",
        ],
        etatas: [
            "دوام -كامل (1)",
            "دوام -جزئى (0،75)",
            "دوام جزئى (0،50)",
            "دوام جزئى (0،25)",
            "مستقل",
        ],
        kalbos: [
            "الليتوانية",
            "الإنجليزية",
            "الروسية ",
            "الألمانية ",
            "اكثر",
            "الفرنسية",
            "الدنماركية ",
            "النوريجية",
            "العربية",
            "الإسبانية",
            "الإيطالية",
            "السويدية",
            "الصينية",
            "الهندية",
            "العبرية",
            "الفارسية",
            "الباشتوية",
            "التركية ",
            "السواحلية",
        ],
        kalbos_lygiai: [
            "اصلى",
            "ممتاز",
            "متوسط",
            "مبتدأ",
        ],
        miestai: [
            "Vilnius",
            "Kaunas",
            "Klaipėda",
            "Šiauliai",
            "Panevėžys",
            "Akmenė",
            "Alytus",
            "Anykščiai",
            "Ariogala",
            "Baltoji Vokė",
            "Birštonas",
            "Biržai",
            "Daugai",
            "Druskininkai",
            "Dūkštas",
            "Dusetos",
            "Eišiškės",
            "Elektrėnai",
            "Ežerėlis",
            "Gargždai",
            "Garliava",
            "Gelgaudiškis",
            "Grigiškės",
            "Ignalina",
            "Jieznas",
            "Jonava",
            "Joniškėlis",
            "Joniškis",
            "Jurbarkas",
            "Kaišiadorys",
            "Kalvarija",
            "Kavarskas",
            "Kazlų Rūda",
            "Kėdainiai",
            "Kelmė",
            "Kybartai",
            "Kretinga",
            "Kudirkos Naumiestis",
            "Kupiškis",
            "Kuršėnai",
            "Lazdijai",
            "Lentvaris",
            "Linkuva",
            "Marijampolė",
            "Mažeikiai",
            "Molėtai",
            "Naujoji Akmenė",
            "Nemenčinė",
            "Neringa",
            "Obeliai",
            "Pabradė",
            "Pagėgiai",
            "Pakruojis",
            "Palanga",
            "Pandėlys",
            "Panemunė",
            "Pasvalys",
            "Plungė",
            "Priekulė",
            "Prienai",
            "Radviliškis",
            "Ramygala",
            "Raseiniai",
            "Rietavas",
            "Rokiškis",
            "Rūdiškės",
            "Salantai",
            "Seda",
            "Simnas",
            "Skaudvilė",
            "Skuodas",
            "Smalininkai",
            "Subačius",
            "Šakiai",
            "Šalčininkai",
            "Šeduva",
            "Šilalė",
            "Šilutė",
            "Širvintos",
            "Švenčionėliai",
            "Švenčionys",
            "Tauragė",
            "Telšiai",
            "Tytuvėnai",
            "Trakai",
            "Troškūnai",
            "Ukmergė",
            "Utena",
            "Užventis",
            "Vabalninkas",
            "Varėna",
            "Varniai",
            "Veisiejai",
            "Venta",
            "Viekšniai",
            "Vievis",
            "Vilkaviškis",
            "Vilkija",
            "Virbalis",
            "Visaginas",
            "Zarasai",
            "Žagarė",
            "Žiežmariai",
        ],
        atlyginimai: [
            "200",
            "250",
            "300",
            "350",
            "450",
            "500",
            "550",
            "600",
            "650",
            "700",
        ],
        darbdavio_top_steps: [
            "البحث عن مجال الوظيفة ",
            "المسمى الوظيفى ",
            "المنصب ",
        ],
        darbdavio_top_Pozicija: [
            "المسمى الوظيفى ",
            "المنصب الوظيفى ",
            "نوع العقد ",
        ],
        darbdavio_top_Sutarties: [
            "المسمى الوظيفى ",
            "المنصب ",
            "ساعات العمل  ",
        ],
        darbdavio_top_Etatas: [
            "نوع العقد",
            "ساعات العمل ",
            "اللغات ",
        ],
        darbdavio_top_Kalbos: [
            "ساعات العمل ",
            "اللغات ",
            "المدينة ",
        ],
        darbdavio_top_Miestas: [
            "اللغات ",
            "المدينة ",
            "الاجر ",
        ],
        darbdavio_top_Atlyginimas: [
            "المدينة ",
            "الاجر ",
            "المسمى الوظيفى ",
        ],
        darbdavio_top_Aprasymas: [
            "الاجر ",
            "المسمى الوظيفى ",
            "اسئلة اضافية ",
        ],
        darbdavio_top_Klausimai: [
            "المسمى الوظيفى ",
            "اسئلة اضافية ",
            "تاريخ الانتهاء ",
        ],
        darbdavio_top_SkelbimoLaikas: [
            "المسمى الوظيفى",
            "اسئلة اضافية",
            "تاريخ الانتهاء",
        ],
        darbo_pozicijos: [
            "عقد عمل ",
            "نشاط فردى ",
            "شهادة عمل ",
            "عقدعمل تطوعى ",
            "اى نوع من العقود ",
        ],
        sutarties_pobudis: [
            "عقد عمل ",
            "نشاط فردى ",
            "شهادة عمل ",
            "عقدعمل تطوعى ",
            "اى نوع من العقود ",
        ],
    }
}