import React, { useState, createContext,  } from "react";
// import { GetData } from "../functions/GetData";
// import Login from "../functions/Log-in";

export const TaskContext = createContext();
const TaskContextProvider = (props) => {

    const [tasks, setTasks] = useState('login');
    const [main, setMain] = useState('kortele');
    const [user, setUser] = useState({});
    const [skelbimas, setSkelbimas] = useState('');
    const [access, setAccess] = useState('');
    const [multi, setMulti] = useState([]);
    const [saugoma, setSaugoma] = useState([]);
    const [baze, setBaze] = useState('baze');
    const [DB, setDB] = useState('baze');
    const [disabled, setDisabled] = useState(true);
    const [kalbaDisabled, setKalbaDisabled] = useState(true);
    // const [routas, setRoutas] = useState('http://scobo-app.herokuapp.com/');//staging   
    const [routas, setRoutas] = useState('http://job-nestjs.herokuapp.com/');//landing
    // http://job-nestjs.herokuapp.com/

    return (
        <TaskContext.Provider value={{ tasks, setTasks, main, setMain,
         user, setUser, skelbimas, setSkelbimas,
         access, setAccess, multi, setMulti,
         saugoma,setSaugoma,
         baze, setBaze, disabled, setDisabled,routas 
         ,kalbaDisabled, setKalbaDisabled
         ,DB, setDB}}>
            {props.children}
        </TaskContext.Provider>
    );
};
export default TaskContextProvider

