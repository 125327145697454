export const ru = {

    translations: {
        welcome: "Привет",
        toliau: "Следующий",
        pradeti: "Старт",
        ar_darbdavys_darbuotojas_text: "Вы работодатель или ищете работу ",
        ieskau_Darbo: "Я ищу работу",
        esu_darbdavys: "Я работодатель",
        Prisijungimas: "Вход",
        Registracija: "Регистрация",
        Registruotis: "Зарегистрироваться",
        google: "Google",
        facebook: "Facebook",
        arba_registruokis: "Или зарегистрироваться",
        email: "Электронная почта",
        atgal: "Назад",
        praleisti: "Пропустить",
        Prie_Scovo: "Подключиться к Scobo",
        prisijungimas: "Вход",
        registracija: "Регистрация",
        slaptazodis: "Пароль",
        pamirsauSlaptazodi: "Забыл пароль",
        Prisijungti: "Вход",
        Pakartoti_slaptazodis: "Повторите пароль",
        Vardas: "Имя",
        Pavarde: "Фамилия",
        Labas: "Привет!",
        Laukiame: "Ждем вашего соединения",
        Pasirinkite_issilavinimo_lygi: "Выберите уровень образования",
        ar_turite_patirties: "Есть ли у вас опыт в этой сфере",
        taip: "Да",
        ne: "Нет",
        kiek_metu: "Сколько у вас лет опыта в этой сфере",
        visi_miestai: "Все города ",
        nuotoliniu: "Удаленная работа",
        hibridinis_darbas: "Гибридная работа",
        nuo: "От",
        iki: "До",
        darbo_aprasymas: "Описание должности и требования ",
        Aprasymas: "Описание",
        kontaktinis_el: "Электронная почта ",
        tel: "Номер телефона",
        kotaktu_sutikimas: "Даю согласие на использоваие предоставленных мной контактов для связи",
        aprasymas_apie_save: "Прикрепить краткое описание о себе",
        uzpildyti_apie_imone: "Заполните информацию о компании",
        imones_pavadinimas: "Название компании",
        apie_imone: "О компании",
        svetaine: "Ссылка на сайт",
        darbuotoju_sk: "Количество сотрудников",
        date: "дд/мм/гг",
        teisineForma: "Юридическая форма компании",
        veiklos_sritys: "Сфера деятельности",
        pridekite_darbo_pasiulyma: "Прикрепите предложение о работе",
        pradekite_naudotis: "Прикрепите предложение и начните пользоваться Scobo!",
        prideti: "Прикрепите ",
        darbo_pareigos: "Заполнить вакансию ",
        grizti: "Назад ",
        klausimas: "Вопрос",
        reikalingas_CV: "Вам нужно резюме сотрудника",
        reikalingas_Linkedin: "Вам нужна ссылка на LinkedIn",
        Profilis: "Профиль",
        Sutape: "Совпавшие",
        istrinti: "Удалить профиль",
        SutapoteSu: "Вы совпали с",
        Susisiekti: "Свяжитесь с нами",
        atsijungti: "Выйти",
        asmenine_informacija: "Личная информация",
        skelbimo_informacija: "Информация профиля",
        slaptazodzio_keitimas: "Смена пароля",
        pagalba: "Помощь",
        pranesimai: "Уведомления",
        issaugoti: "Сообщения",
        sena_slaptazodi: "Введите старый пароль",
        nauja_slaptazodi: "Введите новый пароль",
        nauja_pakartoti_slaptazodi: "Повторите новый пароль",
        Prideti_Skelbima: "Прикрепить новое объявление",
        isikurimo_data: "Дата создания",
        Sutape_darbuotojai: "Совпавшие сотрудники",
        miestas: "Город",
        atlyginimas: "Зарплата (брутто)",
        aprasymas: "Описание",
        sritis: "Сфера",
        daugiau: "Ещё",
        susiekite_elp: "по почте",
        susiekite_numeriu: "по телефону",
        turi_darbo_Skelbima: "Хочет предложить вам работу",
        Sutape_darbdaviai: "Совпавшие работодатели",
        kreipiantis: "Если вы связываетесь по электронной почте, пожалуйста",
        add_CV: "прикрепите резюме",
        ir: "и",
        ats_kls: "ответитьте на эти вопросы",
        kontaktinis: "Укажите адрес электронной почты",
        darbuotoju_baze: "Кадровый резерв",
        issaugoti_darbuotojai: "Сохранённые работники",
        darbo_sritis_filr: "Сфера деятельности",
        kalba_filtr: "Язык",
        filtruoti: "Применить фильтры",
        panaikinti_filtra: "Удалить фильтры",
        istrinti_issaugota: "Удалить ",
        metai: "Год",
        nesutampa_psw: "Пароли не совпадают",
        poke: "Подмигнуть",
        priregistruotas: "Ссылка для подтверждения у вас в почтовом ящике",
        praneskite_skelbima: "Уведомить сотрудника о вакансии",
        poreikis: "Требование",
        ar_tikrai_atsijungti: "Вы уверены, что хотите выйти ",
        ar_tikrai_istrinti: "Вы уверены, что хотите удалить ",
        uzdaryti: "Закрыть ",
        sutinku_prisijunimo: "Я согласен с правилами входа ",
        darbuotojo_aprasymas: "Напишите кратко о себе ",
        klausimai_kandidatam: "Вы можете задать вопросы кандидатам, ответы будут приложены к их анкетам",
        Filtras: "Искать",
        istrinti_sutapusi: "Удалить ",
        darbuotojo_informacija: "Информация о сотруднике",
        filtras: "Фильтр",
        skelbimai: "Объявления",
        darbuotojai: "Сотрудники",
        susipazinau_su_p: "Я ознакомился с политикой персональных данных",
        Jau_priregistruotas: "Электронная почта уже зарегистрирована",
        blogai_ivesti_duomenys: "Введены неверные данные",
        issaugoti_darbdaviai: "Сохранённые работники",
        praneskite_skelbima_darbuotojas: "Сообщите работодателю о себе",
        Jus_issaugojo: "Вас сохранили",
        jumis_susidomejo: "Кто-то Вами заинтересован",
        susi: "Свяжитесь с нами",
        darbuotoju_baze_sutikimas: "Я согласен с тем что личная информация будет отображаться в базе данных для работодателей",
        skelbimu_nera: "Вакансий нет",
        nera_jums_tinkanciu: "Временно нет подходящих вакансий зайдите в ближайшее время снова!",
        siulomi_darbuotojai: "Рекомендуемые сотрудники",
        siulomi_darbdaviai: "Объявления о вакансиях",
        pasirinkti_kalba: "Выберите язык",
        studiju_sritis: "Направление обучения",
        issiusti: "Отправить",
        skelbimas_ijungtas: "Объявление видно",
        skelbimas_isjungtas: "Объявление невидимо",
        psw_visi_tokie_pat: "Не используйте пароль повторно",
        psw_pakeistas: "Пароль изменен",
        psw_skirtingi: "Новый пароль не совпадает",
        psw_blogas: "Неверный пароль",
        blogi_prisijungimas: "Неверные данные для входа ",
        susipazinau_darbdaviams: "Я подтверждаю, что вся информация, которую я предоставляю в приложении SCOBO, является достоверной. Компания принимает на себя полную ответственность за точность предоставленной информации и за обеспечение подходящего рабочего места в соответствии с правовыми актами Литовской Республики.",
        susipazinau_darbuotojams: "Я подтверждаю, что создаю учетную запись от своего имени и что вся информация, которую я предоставляю в приложении SCOBO, является достоверное. Я беру на себя полную ответственность за достоверность предоставленной информации.",
        kita: "Другой",
        passikeisti_varda_pavarde: 'Пожалуйста, обновите свои персональные данные',
        duomenu_politika: 'Политика данных ',
        darbo_skelbimo: 'Информация о вакансии ',
        skelbimas_atnaujintas: 'Объявление успешно обновлено ',
        nera_issaugotos: "Вы еще не сохранили ни одного кандидата",
        svarbus_pakeitimai: "Есть важные изменения, приглашаем  Вас обновить приложение. Вы можете сделать это здесь",
        atsisiusti: 'Скачать',
        pavarde_filtras: "Фамилия",
        vardas_filtras: "Имя",
        netinkantis: 'Пока нет идеально Вам подходящих объявлений, но взгляните на эти',
        tikDarbdaviam: 'ЛОГИН компании',
        reguliarios_komforto_pertraukos: 'Возможны регулярные комфортные перерывы',
        pritaikyta_judeti_vezimelyje: 'Помещения доступны для инвалидов-колясочников',
        atviri_poreikiams: "Мы открыты к адаптации условий труда к индивидуальным потребностям сотрудников.",
        norite_teskstas_atsirastu: 'Установите флажок, если хотите, чтобы этот текст отображался рядом с вашим объявлением.',

        languages: [
            "en",
            "lt",
            "ru",
            "ar",
            "ua",
        ],
        languages_full: [
            "English",
            "Lietuvių",
            "Русский",
            "العربية",
            "Українська",
        ],
        darbuotojo_options_top: [
            "Образование",
            "Направление обучения",
            " Профессиональная сфера",
            "Опыт работы ",
            "Тип контракта",
            "Ставка",
            "Языки",
            "Город",
            " Заработная плата",
            "Описание",
            " Контакты",
        ],
        issilavinimas: [
            "Начальное",
            "Младшее среднее",
            "Высшее среднее",
            "Профессиональное",
            "Высшее",
        ],
        sritys: [
            "Медицина",
            "Стоматология",
            "Фармация",
            "Питание",
            "Реабилитация",
            " Медсестринское дело",
            "Науки о жизни",
            "Сельское хозяйство и ветеринария",
            "Естественные науки",
            "Геология",
            "Биология",
            "Ботаника",
            "Зоология",
            "Генетика",
            "Спорт",
            "Химия",
            "Физика",
            "Окружающая среда",
            "Математика и ИТ",
            "Инженерное дело",
            "Кулинария",
            "Общественные науки",
            "Право",
            "Бизнес и менеджмент",
            "Финансы",
            "Бухгалтерский учет",
            "Туризм",
            "Психология",
            "Социология",
            "Политология",
            "Экономика",
            "Коммуникация",
            "Образование и обучение",
            "Филология",
            "Искусство",
            "История",
            "Философия",
            "Богословие",
            "Культурология",
            "Другое",
        ],
        darbo_sritis: [
            "Сельское хозяйство",
            "Лесное хозяйство",
            "Рыболовство",
            "Производство",
            "Промышленность",
            "Строительство",
            "Оптом и в розницу",
            "Туризм",
            "Гостиничный бизнес",
            "Кейтеринг",
            "Транспорт",
            "Логистика",
            "Связи с общественностью",
            "СМИ",
            "Финансы",
            "Страхование",
            "Недвижимость",
            "Наука",
            "Фармацевтика",
            "Организация",
            "Менеджмент",
            "Продажи",
            "Государственное управление",
            "Оборона",
            "Администрация",
            "Информационные технологии",
            "Образование",
            "Здравоохранение",
            "Социальная работа",
            "Искусство",
            "Развлечения",
            "Отдых",
            "Спорт",
            "Служба поддержки клиентов",
            "Человеческие ресурсы",
            "Неправительственный сектор",
            "Очистка",
        ],
        patirtis_metais: [
            "1-3",
            "3-5",
            "5-10",
            "10 и более",
            " ",
        ],
        sutartis: [
            "Трудовой договор",
            "Индивидуальная деятельность",
            "Индивидуальная деятельность по патенту",
            "Другое",
            " Любой вид контракта",
        ],
        etatas: [
            "025",
            "05",
            "075",
            "1",
        ],
        kalbos: [
            "Литовский",
            "Английский",
            "Русский",
            "Немецкий",
            "Больше",
            "Французский",
            "Датский",
            "Норвежский",
            "Арабский",
            "Испанский",
            "Итальянский",
            "Шведский",
            "Китайский",
            "Хинди",
            "Иврит",
            "Персидский",
            "Пушту",
            "Турецкий",
            "Суахи́ли",
        ],
        kalbos_lygiai: [
            "Родной",
            "Опытный",
            "Средний",
            "Начинающий",
        ],
        miestai: [
            "Vilnius",
            "Kaunas",
            "Klaipėda",
            "Šiauliai",
            "Panevėžys",
            "Akmenė",
            "Alytus",
            "Anykščiai",
            "Ariogala",
            "Baltoji Vokė",
            "Birštonas",
            "Biržai",
            "Daugai",
            "Druskininkai",
            "Dūkštas",
            "Dusetos",
            "Eišiškės",
            "Elektrėnai",
            "Ežerėlis",
            "Gargždai",
            "Garliava",
            "Gelgaudiškis",
            "Grigiškės",
            "Ignalina",
            "Jieznas",
            "Jonava",
            "Joniškėlis",
            "Joniškis",
            "Jurbarkas",
            "Kaišiadorys",
            "Kalvarija",
            "Kavarskas",
            "Kazlų Rūda",
            "Kėdainiai",
            "Kelmė",
            "Kybartai",
            "Kretinga",
            "Kudirkos Naumiestis",
            "Kupiškis",
            "Kuršėnai",
            "Lazdijai",
            "Lentvaris",
            "Linkuva",
            "Marijampolė",
            "Mažeikiai",
            "Molėtai",
            "Naujoji Akmenė",
            "Nemenčinė",
            "Neringa",
            "Obeliai",
            "Pabradė",
            "Pagėgiai",
            "Pakruojis",
            "Palanga",
            "Pandėlys",
            "Panemunė",
            "Pasvalys",
            "Plungė",
            "Priekulė",
            "Prienai",
            "Radviliškis",
            "Ramygala",
            "Raseiniai",
            "Rietavas",
            "Rokiškis",
            "Rūdiškės",
            "Salantai",
            "Seda",
            "Simnas",
            "Skaudvilė",
            "Skuodas",
            "Smalininkai",
            "Subačius",
            "Šakiai",
            "Šalčininkai",
            "Šeduva",
            "Šilalė",
            "Šilutė",
            "Širvintos",
            "Švenčionėliai",
            "Švenčionys",
            "Tauragė",
            "Telšiai",
            "Tytuvėnai",
            "Trakai",
            "Troškūnai",
            "Ukmergė",
            "Utena",
            "Užventis",
            "Vabalninkas",
            "Varėna",
            "Varniai",
            "Veisiejai",
            "Venta",
            "Viekšniai",
            "Vievis",
            "Vilkaviškis",
            "Vilkija",
            "Virbalis",
            "Visaginas",
            "Zarasai",
            "Žagarė",
            "Žiežmariai",
        ],
        atlyginimai: [
            "200",
            "250",
            "300",
            "350",
            "450",
            "500",
            "550",
            "600",
            "650",
            "700",
        ],
        darbdavio_top_steps: [
            "Сфера поиска работы",
            "Название работы ",
            "Должность",
        ],
        darbdavio_top_Pozicija: [
            "Название работы",
            "Должность",
            "Вид контракта",
        ],
        darbdavio_top_Sutarties: [
            "Название работы",
            "Должность",
            "Ставка",
        ],
        darbdavio_top_Etatas: [
            "Вид контракта",
            "Ставка",
            "Языки",
        ],
        darbdavio_top_Kalbos: [
            "Ставка",
            "Языки",
            "Город",
        ],
        darbdavio_top_Miestas: [
            "Языки",
            "Город",
            "Зарплата",
        ],
        darbdavio_top_Atlyginimas: [
            "Город",
            "Зарплата",
            "Описание должности",
        ],
        darbdavio_top_Aprasymas: [
            "Зарплата",
            "Описание должности",
            "  Дополнительные вопросы",
        ],
        darbdavio_top_Klausimai: [
            "Описание должности",
            "Дополнительные вопросы",
            "Срок годности",
        ],
        darbdavio_top_SkelbimoLaikas: [
            "Описание должности",
            "Дополнительные вопросы",
            "Срок годности",
        ],
        darbo_pozicijos: [
            "Младший",
            "Mid",
            "Старший",
            "Исполнительный",
            "Any",
        ],
        sutarties_pobudis: [
            "Трудовой договор",
            "Индивидуальная деятельность",
            "Индивидуальная деятельность по патенту",
            "Волонтерский контракт или стажировка",
            "Любой вид контракта",
        ],
    }
}