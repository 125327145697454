import { useContext, Image } from "react"
import { TaskContext } from "../AppStates"
import Kandidatai from "./kandidatai"
import Expenses from "./kandidatai"
import StartSkelbimas from "./startSkelbimas"
import Login from "./Login"
import { isMobile } from 'react-device-detect';

const Main = () => {
    const { tasks } = useContext(TaskContext)
    // console.log(isMobile)
    return (
        isMobile == false ? (

            tasks == 'login' ? (
                <Login />
            ) : tasks == 'viduje' ? (
                <Kandidatai />
            ) : tasks == 'reg' ? (
                <StartSkelbimas />
            ) : (<div><p>crashed the system?please report</p></div>)
        ) : (
            <div className="centerContainer socialDownload column flex ">
                <h2>DOWNLOAD SCOBO</h2>
                <div className="" onClick={() => {
                    window.location = "https://play.google.com/store/apps/details?id=com.scobo.app"
                }}>
                    <img width="194" height="58" src="https://scobo.lt/wp-content/uploads/2022/01/google-play-black.png" class="elementor-animation-shrink attachment-full size-full" alt="" loading="lazy" />
                </div>
                <div onClick={() => {
                    window.location = "https://apps.apple.com/lt/app/scobo-app/id1604440024"
                }}>
                    <img width="194" height="58" src="https://scobo.lt/wp-content/uploads/2022/02/apple-download-copy.png" class="elementor-animation-shrink attachment-full size-full" alt="" loading="lazy" />
                </div>
            </div>
        )
    )
}
export default Main