
import { useTranslation } from "react-i18next";
import { useContext, useState, useEffect } from "react";
import { TaskContext } from "../AppStates";
import Logins from "../functions/Log-in";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from '@greatsumini/react-facebook-login';
import Logo from "../logo.svg";
import Modal from 'react-modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Prisijungimas = () => {
    const { t } = useTranslation()
    const [vardas, setVardas] = useState('')
    const [slaptazodis, setSlaptazodis] = useState('')
    const [matomas, setMatomas] = useState(false)
    const { routas, setAccess, access, setTasks, setUser } = useContext(TaskContext)
    const LoginDomain = routas + 'users/login';
    const [modalIsOpen2, setModalIsOpen2] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleShow = () => setModalIsOpen(true);
    const [info, setInfo] = useState({})
    const [checked, setChecked] = useState(false)
    const [checked2, setChecked2] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const letsLogIn = async () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: vardas.toLocaleLowerCase(),
                password: slaptazodis,
                device_token_fcm: ''

            })
        };
        try {

            fetch(LoginDomain, requestOptions)
                .then(async response => {
                    await response.json().then(data => {
                        if (data.statusCode == 400 || data.statusCode == 500) {
                            alert(t('blogi_prisijungimas'))
                        }
                        else {
                            setAccess(data.access_token)
                            Logins({ data, routas, setTasks, setUser })
                        }
                    })
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
        catch (e) { console.log(e) }
    }
    const responseGoogle = (response) => {
        try {
            if (response.error != "idpiframe_initialization_failed" || response.error != "popup_closed_by_user") {
                googleLoginScobo(
                    response.profileObj.email,
                    response.profileObj.givenNamem,
                    response.profileObj.fammilyName,
                    response.tokenObj.id_token
                )
            }

        }
        catch (e) { console.log(e) }
    }
    const responseFacebook = (response) => {
        try {
            if (response.error != "idpiframe_initialization_failed" || response.error != "popup_closed_by_user") {
                googleLoginScobo(
                    response.email,
                    response.name.split(' ')[0],
                    response.name.split(' ')[1],
                    access.access_token
                )
            }
        }
        catch (e) { console.log(e) }
    }


    const googleLoginScobo = (email, name, fammilyName, token) => {
        let RegisterDomainGoogle = routas + 'users/googleRegister';
        let random_string = (Math.random() + 1).toString(36).substring(7);
        let registerOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email.toLowerCase(),
                name: name,
                surname: fammilyName,
                access_token: token,
                email_confirmation: true,
                employer: true,
                password: random_string,
            })
        };
        fetch(RegisterDomainGoogle, registerOptions)
            .then(async response => {
                await response.json().then(data => {
                    if (data.statusCode == 400 || data.statusCode == 500) {
                        alert(data.message)
                    }
                    else {
                        setAccess(token)
                        data = { access_token: token }
                        Logins({ data, routas, setTasks, setUser })
                    }
                })
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const handlePamirsau = () => { setModalIsOpen2(true) }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const handleSend = async () => {
        let forgotURl = routas + 'password/forgot/';
        let forgotData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: vardas.toLowerCase(),
            })
        }
        // console.log(forgotURl, forgotData)
        fetch(forgotURl, forgotData)
            .then(async response => {
                if (response.status == 400) {
                }
                else if (response.status == 500) {
                    alert('No working')

                }
                else {
                    alert('Please check your email', vardas)
                    setModalIsOpen2(false);

                }
            })
    };
    return (
        <div className="flex column">
            {/* <h2 className="centerContainer">{t('Prisijungimas')}</h2> */}
            <div className={checked2 ? "toSides socialButtons" : "toSides disabled socialButtons"}>
                <GoogleLogin
                    clientId="69739907645-0egvipvm31j9vnqlgl4sa2hmsungophd.apps.googleusercontent.com"
                    buttonText={t('google')}
                    className="socPrisijungimas"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    uxMode={'popup'}
                    style={{ backgroundColor: 'red' }}
                />
                <FacebookLogin
                    children='Facebook'
                    className="socPrisijungimas"
                    appId="452993872662258"
                    onSuccess={(response) => {

                        console.log('Login Success!', response);
                        setAccess(response.accessToken)
                    }}
                    onFail={(error) => {
                        console.log('Login Failed!', error);
                    }}
                    onProfileSuccess={(response) => {
                        console.log('Get Profile Success!', response);
                        responseFacebook(response)
                    }}
                />
                <div className={checked ? 'none' : 'flex column socialCheckbox'}>
                    <label className="checkBox1">
                        <input
                            type={'checkbox'}
                            checked={checked}
                            onChange={() => {
                                { handleShow(); }
                            }}
                        />
                        {t('sutinku_prisijunimo')}
                    </label>
                </div>
            </div>
            <div className="column flex centerContainer mt-20">
                <input
                    className="input"
                    placeholder={t('email')}
                    value={vardas}
                    onChange={(e) => { setVardas(e.target.value) }}
                />
                <div className="pass">
                    <input
                        className="input"
                        placeholder={t("slaptazodis")}
                        value={slaptazodis}
                        onChange={(e) => { setSlaptazodis(e.target.value) }}
                        type={passwordShown ? 'text' : 'password'}
                    />
{/*                     
                    <VisibilityIcon
                        className="passIcon"
                        onClick={() => {
                            togglePasswordVisiblity()
                            
                        }}

                    /> */}
                                        {passwordShown ? <VisibilityOffIcon className="passIcon"
                        onClick={() => {
                            togglePasswordVisiblity()
                        }} /> : <VisibilityIcon className="passIcon"
                            onClick={() => {
                                togglePasswordVisiblity()
                            }} />}
                    </div>
            </div>
            <a onClick={() => {
                handlePamirsau()
            }} className="openModalLink">{t('pamirsauSlaptazodi')}</a>
            <button className="mainBtn" onClick={() => { letsLogIn() }} >{t('Prisijungimas')}</button>
            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal2"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen(false) }}
            >
                <div
                    className="centerContainer1 column">

                    <div className="closeBtn">

                    </div>
                    <div className="modalInfo" >
                        <div className="politika">
                            <div className="politika"><p>{t('susipazinau_darbdaviams')}</p></div>
                            <label className="checkBox2">
                                <input
                                    type={'checkbox'}
                                    checked={checked2}
                                    onChange={() => {
                                        setChecked2(!checked2)
                                        // setChecked(!checked)
                                    }}
                                />
                                <a href='https://scobo.lt/privacy-policy/' target="_blank">{t('susipazinau_su_p')}</a>
                            </label>
                        </div>
                    </div>
                    <div className={"modalBtnContainer"}  >
                        <button
                            className={!checked2 ? 'modalBtn1 disabled' : 'modalBtn1'}
                            onClick={() => {
                                if (checked2 == true) {
                                    setModalIsOpen(false)
                                    setChecked2(true)
                                    setChecked(true)
                                    setModalIsOpen(false)
                                }
                            }}
                        >{t('taip')}</button>
                        <button
                            onClick={() => {
                                setModalIsOpen(false)
                                setChecked2(false)
                                setChecked(false)
                                setModalIsOpen(false)

                            }}
                            className=" modalBtn1">{t('ne')}</button>
                    </div>
                </div>

            </Modal>
            <Modal
                isOpen={modalIsOpen2}
                ariaHideApp={false}
                className="modal2"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen2(false) }}
            >
                <div
                    className="centerContainer1 column">

                    <div className="closeBtn">

                    </div>
                    <div className="modalInfo" >
                        <div className="politika">
                            <input
                                className="input"
                                value={vardas}
                                onChange={(e) => {
                                    // setChecked2(!checked2)
                                    // setChecked(!checked)
                                    setVardas(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="modalBtnContainer"><button
                        onClick={() => {
                            setModalIsOpen2(false)
                            handleSend()
                        }}
                        className=" modalBtn1">{t('issiusti')}</button>
                        <button
                            onClick={() => {
                                setModalIsOpen2(false)
                            }}
                            className=" modalBtn1">{t('grizti')}</button>
                    </div>
                </div>

            </Modal>
        </div>

    )
}
export default Prisijungimas