export const en = {
    translations: {
        welcome: "Hello",
        toliau: "Next",
        pradeti: "Start",
        ar_darbdavys_darbuotojas_text: "Are you hiring or looking for a job",
        ieskau_Darbo: "I am looking for a job",
        esu_darbdavys: "I am hiring",
        Prisijungimas: "Login",
        Registracija: "Sign up",
        Registruotis: "Sign up",
        google: "Google",
        facebook: "Facebook",
        arba_registruokis: "Or sign up",
        email: "Email",
        atgal: "Back",
        praleisti: "Skip",
        Prie_Scovo: "Connect to Scobo",
        prisijungimas: "Login",
        registracija: "Registration",
        slaptazodis: "Password",
        pamirsauSlaptazodi: "Forgot the password",
        Prisijungti: "Login",
        Pakartoti_slaptazodis: "Repeat password",
        Vardas: "Name",
        Pavarde: "Last Name",
        Labas: "Welcome!",
        Laukiame: "Let's create your account",
        Pasirinkite_issilavinimo_lygi: "Select the level of education",
        ar_turite_patirties: "Do you you have experience in this field",
        taip: "Yes",
        ne: "No",
        kiek_metu: "How many years of experience do you have in this field",
        visi_miestai: "All cities",
        nuotoliniu: "Remote work",
        hibridinis_darbas: "Hybrid work",
        nuo: "From",
        iki: "To",
        darbo_aprasymas: "Job description and requirements",
        Aprasymas: "Description",
        kontaktinis_el: "Contact email",
        tel: "Telephone number",
        kotaktu_sutikimas: "I allow the employer to contact me via the contacts",
        aprasymas_apie_save: "Attach a brief description about yourself",
        uzpildyti_apie_imone: "Fill out the information about the company",
        imones_pavadinimas: "Company's name",
        apie_imone: "About the company",
        svetaine: "Link to a website",
        darbuotoju_sk: "The number of employees",
        date: "dd/mm/yy",
        teisineForma: "Company's legal form",
        veiklos_sritys: "Field of activity",
        pridekite_darbo_pasiulyma: "Attach a job offer",
        pradekite_naudotis: "Attach offer and start using Scobo!",
        prideti: "Attach",
        darbo_pareigos: "Fill in job position",
        grizti: "Back",
        klausimas: "Question",
        reikalingas_CV: "We need an employee's CV",
        reikalingas_Linkedin: "We need a link to LinkedIn",
        Profilis: "Profile",
        Sutape: "Matched",
        istrinti: "Delete profile",
        SutapoteSu: "You matched with",
        Susisiekti: "Get in contact",
        atsijungti: "Sign out",
        asmenine_informacija: "Profile",
        skelbimo_informacija: "Profile information",
        slaptazodzio_keitimas: "Change password",
        pagalba: "Help",
        pranesimai: "Notifications",
        issaugoti: "Save",
        sena_slaptazodi: "Enter your old password",
        nauja_slaptazodi: "Enter your new password",
        nauja_pakartoti_slaptazodi: "Repeat your new password",
        Prideti_Skelbima: "Attach new job ad",
        isikurimo_data: "Founding date",
        Sutape_darbuotojai: "Matched employees",
        miestas: "City",
        atlyginimas: "Salary (Brutto)",
        aprasymas: "Description",
        sritis: "Field",
        daugiau: "More",
        susiekite_elp: "by email",
        susiekite_numeriu: "by phone",
        turi_darbo_Skelbima: "Has a job offer for you",
        Sutape_darbdaviai: "Matched employers",
        kreipiantis: "Getting in contact by email, we ask ",
        add_CV: "to attach CV ",
        ir: "and ",
        ats_kls: "answer these questions: ",
        kontaktinis: "Provide email address",
        darbuotoju_baze: "Pool of employees",
        issaugoti_darbuotojai: "Saved employees",
        darbo_sritis_filr: "Field of work",
        kalba_filtr: "Language",
        filtruoti: "Apply filters",
        panaikinti_filtra: "Delete filters",
        istrinti_issaugota: "Delete",
        metai: "Year",
        nesutampa_psw: "Passwords do not match",
        poke: "Poke",
        priregistruotas: "You will find confirmation link in your email inbox",
        praneskite_skelbima: "Notify an employee about a job ad",
        poreikis: "Need",
        ar_tikrai_atsijungti: "Are you sure you want to sign out",
        ar_tikrai_istrinti: "Are you sure you want to delete",
        uzdaryti: "Close",
        sutinku_prisijunimo: "I agree to the login rules",
        darbuotojo_aprasymas: "Briefly describe yourself",
        klausimai_kandidatam: "You can ask questions to candidates. You will get their answers with candidates questionnaires",
        Filtras: "Search",
        istrinti_sutapusi: "Delete",
        darbuotojo_informacija: "Employee information",
        filtras: "Filter",
        skelbimai: "Ads",
        darbuotojai: "Employees",
        susipazinau_su_p: "I have read the personal data policy",
        Jau_priregistruotas: "Already registered email",
        blogai_ivesti_duomenys: "Incorrect data entered",
        issaugoti_darbdaviai: "Saved employees",
        praneskite_skelbima_darbuotojas: "Inform employer about yourself",
        Jus_issaugojo: "You were noticed",
        jumis_susidomejo: "Company's interested in you",
        susi: "Get in contact",
        darbuotoju_baze_sutikimas: "I agree that personal information will be displayed in the database for employers",
        skelbimu_nera: "There are no ads",
        nera_jums_tinkanciu: "No vacancies available at the moment come back later!",
        siulomi_darbuotojai: "Potential candidates",
        siulomi_darbdaviai: "Job ads",
        pasirinkti_kalba: "Choose a language",
        studiju_sritis: "Field of study",
        issiusti: "Send",
        skelbimas_ijungtas: "The ad is visible",
        skelbimas_isjungtas: "The ad is invisible",
        psw_visi_tokie_pat: "Cannot use the same password",
        psw_pakeistas: "Password changed",
        psw_skirtingi: "The new password does not match",
        psw_blogas: "Incorrect password",
        blogi_prisijungimas: 'Incorrect login information',
        susipazinau_darbdaviams: "I certify that all information I provide in the SCOBO app is correct. The Company assumes full responsibility for the accuracy of the information provided and for ensuring a suitable workplace in accordance with the legal acts of the Republic of Lithuania.",
        susipazinau_darbuotojams: "I certify that I create an account in my own name and that all information I provide in the SCOBO app is correct. I take full responsibility for the accuracy of the information provided.",
        kita: "Other",
        passikeisti_varda_pavarde: 'Please update your personal information',
        duomenu_politika: 'Data policy',
        darbo_skelbimo: 'Job ad information',
        skelbimas_atnaujintas: 'Ad successfully updated ',
        nera_issaugotos: "You haven't saved any candidates yet",
        svarbus_pakeitimai: "There are important changes, we invite you to update the app. You can do it here",
        atsisiusti: 'Download',
        pavarde_filtras: "Last name",
        vardas_filtras: "Name",
        netinkantis: 'No perfect match this time, but take a look at these',
        tikDarbdaviam: 'Company LOGIN',
        reguliarios_komforto_pertraukos: 'Regular comfort breaks are available',
        pritaikyta_judeti_vezimelyje: 'The premises are wheelchair accessible',
        atviri_poreikiams: "We are open to adapting working conditions to the individual needs of employees",
        norite_teskstas_atsirastu: 'Check the box if you want this text to appear next to your ad.m',

        languages: [
            "en",
            "lt",
            "ru",
            "ar",
            "ua",
        ],
        languages_full: [
            "English",
            "Lietuvių",
            "Русский",
            "العربية",
            "Українська",
        ],
        darbuotojo_options_top: [
            "Education",
            "Field of study",
            "Professional field",
            "Experience",
            "Type of contract",
            "Hours",
            "Languages",
            "City",
            "Salary",
            "Description",
            "Contacts",
        ],
        issilavinimas: [
            "Primary",
            "Lower secondary",
            "Higher secondary",
            "Professional",
            "Higher",
        ],
        sritys: [
            "Medicine",
            "Dentistry",
            "Pharmacy",
            "Nutrition",
            "Rehabilitation",
            "Nursing",
            "Life Sciences",
            "Agriculture and Veterinary",
            "Natural Sciences",
            "Geology",
            "Biology",
            "Botany",
            "Zoology",
            "Genetics",
            "Sports",
            "Chemistry",
            "Physics",
            "Environment",
            "Mathematics and IT",
            "Engineering ",
            "Culinary",
            "Social Sciences",
            "Law",
            "Business and Management",
            "Finance",
            "Accounting",
            "Tourism",
            "Psychology",
            "Sociology",
            "Political Science",
            "Economics",
            "Communication",
            "Education and Training",
            "Philology",
            "Arts",
            "History",
            "Philosophy",
            "Theology",
            "Cultural Studies",
            "Other",
        ],
        darbo_sritis: [
            "Agriculture",
            "Forestry",
            "Fisheries ",
            "Manufacturing",
            "Industry",
            "Construction",
            "Wholesale and Retail",
            "Tourism",
            "Hospitality",
            "Catering",
            "Transportation ",
            "Logistics",
            "Public Relations",
            "Media",
            "Finance",
            "Insurance",
            "Real Estate",
            "Science",
            "Pharmaceutics",
            "Organization ",
            "Management",
            "Sales",
            "Public Policy",
            "Defense",
            "Administration",
            "Information Technology",
            "Education",
            "Healthcare",
            "Social Work",
            "Arts",
            "Entertainment",
            "Recreation",
            "Sports",
            "Customer Service",
            "Human Resources",
            "Non-Governmental Sector",
            "Cleaning",
        ],
        patirtis_metais: [
            "1-3",
            "3-5",
            "5-10",
            "10 and more",
        ],
        sutartis: [
            "Job contract",
            "Individual activity",
            "Business certificate",
            "Other",
            "Any type of contract",
        ],
        etatas: [
            "Full-time (1)",
            "Part-time (0.75)",
            "Part-time (0.5)",
            "Part-time (0.25)",
            "Freelance",
        ],
        kalbos: [
            "Lithuanian",
            "English",
            "Russian",
            "German",
            "More",
            "French",
            "Danish",
            "Norwegian",
            "Arabic",
            "Spanish",
            "Italian",
            "Swedish",
            "Chinese",
            "Hindi",
            "Hebrew",
            "Persian",
            "Pashto",
            "Turkish",
            "Swahili",
        ],
        kalbos_lygiai: [
            "Native",
            "Full professional proficiency",
            "Limited working proficiency",
            "Beginner",
        ],
        miestai: [
            "Vilnius",
            "Kaunas",
            "Klaipėda",
            "Šiauliai",
            "Panevėžys",
            "Akmenė",
            "Alytus",
            "Anykščiai",
            "Ariogala",
            "Baltoji Vokė",
            "Birštonas",
            "Biržai",
            "Daugai",
            "Druskininkai",
            "Dūkštas",
            "Dusetos",
            "Eišiškės",
            "Elektrėnai",
            "Ežerėlis",
            "Gargždai",
            "Garliava",
            "Gelgaudiškis",
            "Grigiškės",
            "Ignalina",
            "Jieznas",
            "Jonava",
            "Joniškėlis",
            "Joniškis",
            "Jurbarkas",
            "Kaišiadorys",
            "Kalvarija",
            "Kavarskas",
            "Kazlų Rūda",
            "Kėdainiai",
            "Kelmė",
            "Kybartai",
            "Kretinga",
            "Kudirkos Naumiestis",
            "Kupiškis",
            "Kuršėnai",
            "Lazdijai",
            "Lentvaris",
            "Linkuva",
            "Marijampolė",
            "Mažeikiai",
            "Molėtai",
            "Naujoji Akmenė",
            "Nemenčinė",
            "Neringa",
            "Obeliai",
            "Pabradė",
            "Pagėgiai",
            "Pakruojis",
            "Palanga",
            "Pandėlys",
            "Panemunė",
            "Pasvalys",
            "Plungė",
            "Priekulė",
            "Prienai",
            "Radviliškis",
            "Ramygala",
            "Raseiniai",
            "Rietavas",
            "Rokiškis",
            "Rūdiškės",
            "Salantai",
            "Seda",
            "Simnas",
            "Skaudvilė",
            "Skuodas",
            "Smalininkai",
            "Subačius",
            "Šakiai",
            "Šalčininkai",
            "Šeduva",
            "Šilalė",
            "Šilutė",
            "Širvintos",
            "Švenčionėliai",
            "Švenčionys",
            "Tauragė",
            "Telšiai",
            "Tytuvėnai",
            "Trakai",
            "Troškūnai",
            "Ukmergė",
            "Utena",
            "Užventis",
            "Vabalninkas",
            "Varėna",
            "Varniai",
            "Veisiejai",
            "Venta",
            "Viekšniai",
            "Vievis",
            "Vilkaviškis",
            "Vilkija",
            "Virbalis",
            "Visaginas",
            "Zarasai",
            "Žagarė",
            "Žiežmariai",
        ],
        atlyginimai: [
            "200",
            "250",
            "300",
            "350",
            "450",
            "500",
            "550",
            "600",
            "650",
            "700",
        ],
        darbdavio_top_steps: [
            "Job search field ",
            "Tittle",
            "Position",
        ],
        darbdavio_top_Pozicija: [
            "Tittle",
            "Position",
            "Type of contract ",
        ],
        darbdavio_top_Sutarties: [
            "Tittle",
            "Position",
            "Hours",
        ],
        darbdavio_top_Etatas: [
            "Type of contract",
            "Hours",
            "Languages"
        ],
        darbdavio_top_Kalbos: [
            "Hours",
            "Languages",
            "City",
        ],
        darbdavio_top_Miestas: [
            "Languages",
            "City",
            "Salary",
        ],
        darbdavio_top_Atlyginimas: [
            "City",
            "Salary",
            "Job description",
        ],
        darbdavio_top_Aprasymas: [
            "Salary ",
            "Job description",
            "Additional questions",
        ],
        darbdavio_top_Klausimai: [
            "Job description",
            "Additional questions",
            "Expiration date",
        ],
        darbdavio_top_SkelbimoLaikas: [
            "Job description",
            "Additional questions",
            "Expiration date",
        ],
        darbo_pozicijos: [
            "Junior",
            "Mid",
            "Senior",
            "Executive",
            "Any",
        ],
        sutarties_pobudis: [
            "Job contract",
            "Individual activity",
            "Business certificate",
            "Internship or volunteering contract",
            "Any type of contract",
        ],
    }
}