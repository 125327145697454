import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskContext } from "../AppStates";
import Select from 'react-select'; 
import Modal from 'react-modal';



export default function Profile() {
    const { user, routas, setTasks, setUser } = useContext(TaskContext)
    const [pavadinimas, setPavadinimas] = useState(user.company_name)
    const [apie, setApie] = useState(user.about_company)
    const [url, setUrl] = useState(user.company_url)
    const [sk, setSK] = useState(user.number_of_emploees)
    const [kur, setKur] = useState(0)
    const [pas, setPas] = useState('')
    const [pas2, setPas2] = useState('')
    const [pas3, setPas3] = useState('')
    const { t, i18n } = useTranslation()
    const [currentLng, setCurrentLng] = useState(i18n.language)
    // const [sritysD, setsritysD] = useState('')

    var sritys = []

    t('sritys').forEach((e, i) => {
        sritys.push({ value: i, label: e })
    })
    sritys = sritys.sort((a, b) => a.label > b.label ? 1 : -1)


    const gather = (item, vertimas) => {
        if (item == undefined) { return '' }
        let splited = item.split(',')
        let arr = []
        splited.forEach(element => {
            if (!isNaN(element) && element) {
                arr.push({
                    value: element,
                    label: vertimas[element]
                })
            }

        });
        return arr
    }
    const [sritysO, setsritysO] = useState(gather(user.darbo_sritis, t('sritys')))


    const istrint = async () => {
        let deleteRoute = routas + 'users/' + user._id;

        let trinti = {
            method: 'delete',
            headers: { 'Content-Type': 'application/json' },
        }
        try {
            const response = await fetch(deleteRoute, trinti)
            const json = await response.json().then(data => {
                window.location = "/"
                setTasks('login')
            })
        } catch (error) {
            console.error(error);
        }

    }
    const apdoroti = (item) => {
        if (item == null) { return }
        if (item != null || item != '')
            try {
                let data = ''
                item.forEach((e, i) => {
                    if (i != item.length - 1) {
                        data += e.value + ','
                    } else { data += e.value }
                })
                return data
            }
            catch (e) { console.log(e) }
    }
    const updateInfo = async () => {
        if (pavadinimas == '') { alert('cannot be empty'); return }
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:
                JSON.stringify({
                    darbo_sritis: apdoroti(sritysO),
                    company_name: pavadinimas,
                    about_company: apie,
                    company_url: url,
                    number_of_emploees: sk,
                    darbo_sritis: apdoroti(sritysO)
                })
        };
        let update_employe = routas + 'users/update/' + user.access_token;
        try {
            const response = await fetch(update_employe, requestOptions)
            const json = await response.json().then(data => {
                console.log(data)
                setUser(data)
                alert('updated')
            })
        } catch (error) {
            console.error(error);
        }
    };
    const updatePass = async () => {
        try {
            let pswRoute = routas + `users/${user._id}/password`;

            let updatePSW = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    password: pas,
                    updated_password: pas2
                })
            }
            if (pas2 == pas3) {
                if (pas == pas2 && pas3 == pas) {
                    alert(t('psw_visi_tokie_pat'))
                    return
                }
                const response = await fetch(pswRoute, updatePSW)
                const json = await response.json().then(data => {
                    // console.log(data)
                    if (data.statusCode == 400) {
                        alert(t('psw_blogas'))
                    }
                    else {
                        alert(t('psw_pakeistas'))
                    }
                }

                )
            }
            else {
                alert(t('psw_skirtingi'))
            }
        }
        catch (e) {

        }
    }


    useEffect(() => {
        setsritysO(gather(user.darbo_sritis, t('sritys')))
        setPavadinimas(user.company_name)
        setApie(user.about_company)
        setUrl(user.company_url)
        setSK(user.number_of_emploees)
    },
        [user])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    // const handleClose = () => setModalIsOpen(false);
    const handleShow = () => setModalIsOpen(true);
    const [info, setInfo] = useState({})


    return (
        <main style={{ padding: "1rem 0" }}>
            <h2>{t('Profilis')}</h2>
            <div className="mainSkelbimai">

                <div className="">
                    <div className="rightContainer2">


                        <div
                            // className={'adsList1'}
                        >
                            <button
                                className={'adsList1'}
                                onClick={() => {
                                    setKur(0)
                                }}
                            >{t('apie_imone')}</button>
                            <button
                                className={'adsList1'}
                                onClick={() => {
                                    setKur(1)
                                }}
                            >{t('slaptazodzio_keitimas')}</button>
                            <button
                                className={'adsList1'}
                                onClick={() => { window.open('https://scobo.lt/' + currentLng + '/privacy-policy/', '_blank'); }}
                            >{t('duomenu_politika')}</button>
                        <div style={{ marginLeft: 0, marginTop: 0 }}>
                            <button className="adsList2"
                                // onClick={() => {

                                //     // setVisible(!visible)
                                //     // setDialog('istrinti')
                                //     istrint()

                                // }}
                                onClick={() => { handleShow(); setInfo(info) }}
                            >
                                {t('istrinti')}
                                {/* <Icon name="close" size={20} /> */}
                            </button>
                        </div>
                        </div>

                    </div>

                </div>

                <div className="">
                    <div className=" centerContainer">
                        {kur == 0 ? (
                            <div className="rightContainer1">
                                <h2> {t('apie_imone')}</h2>
                                <input
                                    required
                                    className="input"
                                    placeholder={t('imones_pavadinimas')}
                                    value={pavadinimas}
                                    onChange={(e) => { setPavadinimas(e.target.value) }}
                                />
                                <textarea
                                    type="textarea"
                                    className="textarea"
                                    placeholder={t('apie_imone')}
                                    value={apie}
                                    onChange={(e) => { setApie(e.target.value) }}
                                />
                                <input
                                    className="input"
                                    placeholder={t('svetaine')}
                                    value={url}
                                    onChange={(e) => { setUrl(e.target.value) }}
                                />
                                <input
                                    className="input"
                                    type={'number'}
                                    placeholder={t('darbuotoju_sk')}
                                    value={sk}
                                    onChange={(e) => { setSK(e.target.value) }}
                                />
                                <Select
                                    className="select"
                                    placeholder={t('veiklos_sritys')}
                                    isMulti={true}
                                    value={sritysO}
                                    onChange={(value) => {
                                        console.log(sritysO, value)
                                        // if(sritysO.)

                                        setsritysO(value)
                                    }}
                                    options={sritys}
                                />
                                <button
                                    onClick={() => {
                                        updateInfo()
                                    }}
                                    className="mainBtn2 ">{t('issaugoti')}</button>
                            </div>
                        ) : (
                            <div className="rightContainer1">
                                <h2> {t('slaptazodzio_keitimas')}</h2>
                                <input
                                    required
                                    type={'password'}
                                    className="input"
                                    placeholder={t('sena_slaptazodi')}
                                    value={pas}
                                    onChange={(e) => { setPas(e.target.value) }}
                                />
                                <input
                                    required
                                    type={'password'}
                                    className="input"
                                    placeholder={t('nauja_slaptazodi')}
                                    value={pas2}
                                    onChange={(e) => { setPas2(e.target.value) }}
                                />
                                <input
                                    required
                                    type={'password'}
                                    className="input"
                                    placeholder={t('nauja_pakartoti_slaptazodi')}
                                    value={pas3}
                                    onChange={(e) => { setPas3(e.target.value) }}
                                />

                                <button
                                    onClick={() => {
                                        updatePass()
                                    }}
                                    className="mainBtn2 ">{t('issaugoti')}</button>
                            </div>
                        )}

                    </div>


                </div>

            </div>
            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={ ()=>{setModalIsOpen(false)}}
            >
                <div
                    className="centerContainer1 column">

                    <div className="closeBtn">
                        <button onClick={() => {
                            setModalIsOpen(false)
                            // let last = document.querySelectorAll('[aria-label="Remove ' + currentKalba.label + '"]')
                            // last[0].click()
                        }}>✖</button>
                    </div>
                    {console.log(info)}
                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_istrinti')}?</h4>
                    </div>
                    <div className={"modalBtnContainer"}  >

                        <button
                            onClick={() => {

                                // setVisible(!visible)
                                // setDialog('istrinti')
                                setModalIsOpen(false)
                                istrint()

                            }}
                            className=" modalBtn1">{t('taip')}</button>
                                                    <button
                            onClick={() => {
                                setModalIsOpen(false)
                            }}
                            className=" modalBtn1">{t('ne')}</button>
                    </div>
                    
                </div>

            </Modal>
        </main>
    );
}