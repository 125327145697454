export const lt = {

    translations: {
        welcome: "Sveiki",
        toliau: "Toliau",
        pradeti: "Pradėti",
        ar_darbdavys_darbuotojas_text: "Esate darbdavys ar ieškote darbo ",
        ieskau_Darbo: "Ieškau darbo",
        esu_darbdavys: "Esu darbdavys",
        Prisijungimas: "Prisijungimas",
        Registracija: "Registracija",
        Registruotis: "Registruotis",
        google: "Google",
        facebook: "Facebook",
        arba_registruokis: "Arba registruokitės",
        email: "Įrašykite el. paštą",
        atgal: "Atgal",
        praleisti: "Praleisti ",
        Prie_Scovo: "Prisijunkite prie Scobo",
        prisijungimas: "Prisijungimas",
        registracija: "Registracija",
        slaptazodis: "Įrašykite slaptažodį",
        pamirsauSlaptazodi: "Pamiršote slaptažodį",
        Prisijungti: "Prisijungti",
        Pakartoti_slaptazodis: "Pakartokite slaptažodį",
        Vardas: "Įrašykite savo vardą",
        Pavarde: "Įrašykite savo pavardę",
        Labas: "Sveiki atvykę!",
        Laukiame: "Pradėkite pildyti anketą",
        Pasirinkite_issilavinimo_lygi: "Pasirinkite išsilavinimo lygį",
        ar_turite_patirties: "Ar turite patirties šioje srityje",
        taip: "Taip",
        ne: "Ne",
        kiek_metu: "Kiek metų patirties turite šioje srityje",
        visi_miestai: "Visi miestai",
        nuotoliniu: "Darbas nuotoliniu būdu",
        hibridinis_darbas: "Hibridinis darbas",
        nuo: "Nuo",
        iki: "Iki",
        darbo_aprasymas: "Darbo aprašymas reikalavimai",
        Aprasymas: "Aprašymas",
        kontaktinis_el: "Kontaktinis el. paštas",
        tel: "Telefono numeris",
        kotaktu_sutikimas: "Sutinku, kad darbdavys susisiektų nurodytais kontaktais",
        aprasymas_apie_save: "Pridėkite trumpą aprašymą apie save",
        uzpildyti_apie_imone: "Užpildykite duomenis apie įmonę",
        imones_pavadinimas: "Įmonės pavadinimas",
        apie_imone: "Apie įmonę",
        svetaine: "Internetinės svetainės nuoroda",
        darbuotoju_sk: "Darbuotojų skaičius",
        date: "dd/mėn/mm",
        teisineForma: "Įmonės teisinė forma",
        veiklos_sritys: "Veiklos sritis",
        pridekite_darbo_pasiulyma: "Pridėkite darbo pasiūlymą",
        pradekite_naudotis: "Pridėkite pasiūlymą ir pradėkite naudotis Scobo!",
        prideti: "Pridėti",
        darbo_pareigos: "Įrašykite darbo pareigas",
        grizti: "Grįžti",
        klausimas: "Klausimas",
        reikalingas_CV: "Reikalingas darbuotojo CV",
        reikalingas_Linkedin: "Reikalingas darbuotojo LinkedIn",
        Profilis: "Profilis",
        Sutape: "Sutapę",
        istrinti: "Ištrinti profilį",
        SutapoteSu: "Sutapote su",
        Susisiekti: "Susisiekti",
        atsijungti: "Atsijungti",
        asmenine_informacija: "Prisijungimo duomenys",
        skelbimo_informacija: "Profilio informacija",
        slaptazodzio_keitimas: "Slaptažodžio keitimas",
        pagalba: "Pagalba",
        pranesimai: "Pranešimai",
        issaugoti: "Išsaugoti",
        sena_slaptazodi: "Įrašykite seną slaptažodį",
        nauja_slaptazodi: "Įrašykite naują slaptažodį",
        nauja_pakartoti_slaptazodi: "Pakartokite naują slaptažodį",
        Prideti_Skelbima: "Pridėti naują skelbimą",
        isikurimo_data: "Įsikūrimo data",
        Sutape_darbuotojai: "Sutape darbuotojai",
        miestas: "Miestas",
        atlyginimas: "Atlyginimas (Bruto)",
        aprasymas: "Aprašymas",
        sritis: "Sritis",
        daugiau: "Daugiau",
        susiekite_elp: "El. paštu",
        susiekite_numeriu: "Kontaktiniu numeriu",
        turi_darbo_Skelbima: "Turi darbo pasiūlymą jums",
        Sutape_darbdaviai: "Sutapę darbdaviai",
        kreipiantis: "Kreipiantis elektroninu paštu prašome ",
        add_CV: "pridėti CV ",
        ir: "ir ",
        ats_kls: "atsakyti į šiuos klausimus: ",
        kontaktinis: "Įveskite kontaktinį el. paštą",
        darbuotoju_baze: "Darbuotojų bazė",
        issaugoti_darbuotojai: "Išsaugoti darbuotojai",
        darbo_sritis_filr: "Darbo sritis",
        kalba_filtr: "Kalba",
        filtruoti: "Filtruoti",
        panaikinti_filtra: "Panaikinti filtravimą",
        istrinti_issaugota: "Ištrinti",
        metai: "metai",
        nesutampa_psw: "Slaptažodžiai nesutampa",
        poke: "poke",
        priregistruotas: "Patvirtinimo nuorodą rasite savo el. pašto dėžutėje",
        praneskite_skelbima: "Praneškite darbuotojui apie darbo skelbimą",
        poreikis: "Poreikis",
        ar_tikrai_atsijungti: "Ar tikrai norite atsijungti",
        ar_tikrai_istrinti: "Ar tikrai norite ištrinti",
        uzdaryti: "Uždaryti",
        sutinku_prisijunimo: "Sutinku su prisijungimo taisyklėmis",
        darbuotojo_aprasymas: "Trumpai parašykite apie save",
        klausimai_kandidatam: "Galite užduoti klausimų kandidatams. Atsakymus gausite su kandidatų anketomis",
        Filtras: "Ieškoti",
        istrinti_sutapusi: "Ištrinti",
        darbuotojo_informacija: "Darbuotojo informacija",
        filtras: "Filtras",
        skelbimai: "Skelbimai",
        darbuotojai: "Darbuotojai",
        susipazinau_su_p: "Susipažinau su asmens duomenų politika",
        Jau_priregistruotas: "Šis el. paštas jau egzistuoja",
        blogai_ivesti_duomenys: "Blogai įvesti duomenys",
        issaugoti_darbdaviai: "Išsaugoti darbdaviai",
        praneskite_skelbima_darbuotojas: "Pranškite darbdaviui apie save",
        Jus_issaugojo: "Jus išsaugojo",
        jumis_susidomejo: "Jumis susidomejo",
        susi: "Susisiekite",
        darbuotoju_baze_sutikimas: "Sutinku, kad asmeninė informacija būtų rodoma duomenų bazėje darbdaviams",
        skelbimu_nera: "Tinkančių kandidatų laikinai nėra, užsukite neužilgo vėl!",
        nera_jums_tinkanciu: "Laikinai nėra tinkančių darbo skelbimų užsukite neužilgo vėl!",
        siulomi_darbuotojai: "Kandidatai",
        siulomi_darbdaviai: "Darbo skelbimai",
        pasirinkti_kalba: "Pasirinkti kalbą",
        studiju_sritis: "Studijų sritis",
        issiusti: "Išsiųsti",
        skelbimas_ijungtas: 'Skelbimas yra matomas',
        skelbimas_isjungtas: 'Skelbimas yra nematomas',
        psw_visi_tokie_pat: 'Naujas slaptažodis toks pat kaip senas',
        psw_pakeistas: 'Slaptažodis pakeistas',
        psw_skirtingi: 'Naujas slaptažodis nesutampa',
        blogi_prisijungimas: 'Neteisingi prisijungimo duomenys',
        psw_blogas: 'Slaptažodis neteisingas',
        susipazinau_darbdaviams: 'Patvirtinu, kad visa informacija, kurią pateiksiu programėlėje SCOBO, yra teisinga. Įmonė priisima visą atsakomybę už pateiktos informacijos tikslumą ir tinkamos darbo vietos užtikrinimą pagal Lietuvos Respublikos teisės aktus.',
        susipazinau_darbuotojams: 'Patvirtinu, kad kuriu paskyrą savo vardu ir visa informacija, kurią pateiksiu programėlėje SCOBO, yra teisinga. Prisiimu visą atsakomybę už pateiktos informacijos tikslumą.',
        kita: "Kita",
        duomenu_politika: 'Duomenų politika',
        passikeisti_varda_pavarde: 'Užpildykite asmenine informacija',
        darbo_skelbimo: 'Darbo skelbimo informacija',
        skelbimas_atnaujintas: 'Skelbimas sėkmingai atnaujintas',
        nera_issaugotos: "Kol kas neišsisaugojote nė vieno kandidato",
        svarbus_pakeitimai: "Yra svarbių pakeitimų, kviečiame atsinaujinti programėlę. Tai galite padaryti čia",
        atsisiusti: 'Parsisiųsti',
        pavarde_filtras: "Pavardė",
        vardas_filtras: "Vardas",
        netinkantis: 'Jums tinkamų skelbimų kol kas nėra, bet peržvelkite šiuos',
        tikDarbdaviam: 'Prisijungimas įmonėms',
        reguliarios_komforto_pertraukos: 'Galimos reguliarios komforto pertraukos',
        pritaikyta_judeti_vezimelyje: 'Patalpos pritaikytos judėti vežimėlyje',
        atviri_poreikiams: "Esame atviri pritaikyti darbo sąlygas individualiems darbuotojų poreikiams",
        norite_teskstas_atsirastu: 'Pažymėkite varnelę, jei norite, kad šis tekstas atsidurtų šalia jūsų skelbimo.',
        languages: [
            "en",
            "lt",
            "ru",
            "ar",
            "ua",
        ],
        languages_full: [
            "English",
            "Lietuvių",
            "Русский",
            "العربية",
            "Українська",
        ],
        darbuotojo_options_top: [
            "Išsilavinimas",
            "Studiju sritis",
            "Darbo sritis",
            "Patirtis",
            "Sutarties pobūdis",
            "Etatas",
            "Kalbos",
            "Miestas",
            "Atlyginimas",
            "Aprašymas",
            "Kontaktai",
        ],
        darbuotojo_options_Kontaktai: [
            "Atlyginimas",
            "Aprašymas",
            "Kontaktai",
        ],
        issilavinimas: [
            "Pradinis",
            "Pagrindinis",
            "Vidurinis",
            "Profesinis",
            "Aukštasis",
        ],
        sritys: [
            "Medicina",
            "Odontologija",
            "Farmacija",
            "Mityba",
            "Reabilitacija",
            "Slauga",
            "Gyvybės mokslai",
            "Žemės ūkis ir veterinarija",
            "Gamtos mokslai",
            "Geologija",
            "Biologija",
            "Botanika",
            "Zoologija",
            "Genetika",
            "Sportas",
            "Chemija",
            "Fizika",
            "Aplinkotyra",
            "Matematika ir IT",
            "Inžinerija",
            "Technologijos",
            "Kulinarija",
            "Socialiniai mokslai",
            "Teisė",
            "Verslas ir vadyba",
            "Finansai",
            "Apskaita",
            "Turizmas",
            "Psichologija",
            "Sociologija",
            "Politikos mokslai",
            "Ekonomika",
            "Komunikacija",
            "Švietimas ir ugdymas",
            "Filologija",
            "Menai",
            "Istorija",
            "Filosofija",
            "Teologija",
            "Kultūros studijos",
            "Kita",
        ],
        darbo_sritis: [
            "Žemės ūkis",
            "Miškininkystė",
            "Žuvininkystė",
            "Gamyba",
            "Pramonė",
            "Statyba",
            "Didmeninė ir mažmeninė prekyba",
            "Turizmas ",
            "Apgyvendinimas",
            "Maitinimas",
            "Transportas",
            "Logistika",
            "Viešieji ryšiai",
            "Žiniasklaida",
            "Finansai",
            "Draudimas",
            "Nekilnojamas turtas",
            "Mokslas",
            "Farmacija",
            "Organizavimas ",
            "Valdymas",
            "Pardavimai",
            "Viešoji politika",
            "Gynyba",
            "Administravimas",
            "Informacinės technologijos",
            "Švietimas",
            "Sveikatos priežiūra",
            "Socialinis darbas ",
            "Menas",
            "Pramogos",
            "Poilsis",
            "Sportas",
            "Klientų aptarnavimas",
            "Žmogiškieji ištekliai",
            "Nevyriausybinis sektorius",
            "Valymas",
        ],
        patirtis_metais: [
            "1-3",
            "3-5",
            "5-10",
            "10 ir daugiau",
        ],
        sutartis: [
            "Darbo sutartis",
            "Individuali veikla",
            "Verslo liudijimas",
            "Praktikos/ Savanorystės sutartis",
            "Bet kuri sutarties rūšis",
        ],
        etatas: [
            "0.25",
            "0.5",
            "0.75",
            "1",
            "Laisvai samdomas/-a"
        ],
        kalbos: [
            "Lietuvių",
            "Anglų",
            "Rusų",
            "Vokiečių",
            "Daugiau",
            "Prancūzų",
            "Danų",
            "Norvegų",
            "Arabų",
            "Ispanų",
            "Italų",
            "Švedų",
            "Kinų",
            "Indų",
            "Hebrajų",
            "Persų",
            "Puštonų",
            "Turkų",
            "Suahilių",
        ],
        kalbos_lygiai: [
            "Gimtoji kalba",
            "Laisvai kalbantis",
            "Vidutiniškai kalbantis",
            "Pradedantysis",
        ],
        miestai: [
            "Vilnius",
            "Kaunas",
            "Klaipėda",
            "Šiauliai",
            "Panevėžys",
            "Akmenė",
            "Alytus",
            "Anykščiai",
            "Ariogala",
            "Baltoji Vokė",
            "Birštonas",
            "Biržai",
            "Daugai",
            "Druskininkai",
            "Dūkštas",
            "Dusetos",
            "Eišiškės",
            "Elektrėnai",
            "Ežerėlis",
            "Gargždai",
            "Garliava",
            "Gelgaudiškis",
            "Grigiškės",
            "Ignalina",
            "Jieznas",
            "Jonava",
            "Joniškėlis",
            "Joniškis",
            "Jurbarkas",
            "Kaišiadorys",
            "Kalvarija",
            "Kavarskas",
            "Kazlų Rūda",
            "Kėdainiai",
            "Kelmė",
            "Kybartai",
            "Kretinga",
            "Kudirkos Naumiestis",
            "Kupiškis",
            "Kuršėnai",
            "Lazdijai",
            "Lentvaris",
            "Linkuva",
            "Marijampolė",
            "Mažeikiai",
            "Molėtai",
            "Naujoji Akmenė",
            "Nemenčinė",
            "Neringa",
            "Obeliai",
            "Pabradė",
            "Pagėgiai",
            "Pakruojis",
            "Palanga",
            "Pandėlys",
            "Panemunė",
            "Pasvalys",
            "Plungė",
            "Priekulė",
            "Prienai",
            "Radviliškis",
            "Ramygala",
            "Raseiniai",
            "Rietavas",
            "Rokiškis",
            "Rūdiškės",
            "Salantai",
            "Seda",
            "Simnas",
            "Skaudvilė",
            "Skuodas",
            "Smalininkai",
            "Subačius",
            "Šakiai",
            "Šalčininkai",
            "Šeduva",
            "Šilalė",
            "Šilutė",
            "Širvintos",
            "Švenčionėliai",
            "Švenčionys",
            "Tauragė",
            "Telšiai",
            "Tytuvėnai",
            "Trakai",
            "Troškūnai",
            "Ukmergė",
            "Utena",
            "Užventis",
            "Vabalninkas",
            "Varėna",
            "Varniai",
            "Veisiejai",
            "Venta",
            "Viekšniai",
            "Vievis",
            "Vilkaviškis",
            "Vilkija",
            "Virbalis",
            "Visaginas",
            "Zarasai",
            "Žagarė",
            "Žiežmariai",
        ],
        atlyginimai: [
            "200",
            "250",
            "300",
            "350",
            "450",
            "500",
            "550",
            "600",
            "650",
            "700",
        ],
        darbdavio_top_steps: [
            "Darbo sritis",
            "Pareigos",
            "Darbo Pozicija",
        ],
        darbdavio_top_Pozicija: [
            "Pareigos",
            "Darbo Pozicija",
            "Sutarties pobūdis",
        ],
        darbdavio_top_Sutarties: [
            "Darbo Pozicija",
            "Sutarties pobūdis",
            "Etatas",
        ],
        darbdavio_top_Etatas: [
            "Sutarties pobūdis",
            "Etatas",
            "Kalbos",
        ],
        darbdavio_top_Kalbos: [
            "Etatas",
            "Kalbos",
            "Miestas",
        ],
        darbdavio_top_Miestas: [
            "Kalbos",
            "Miestas",
            "Atlyginimas",
        ],
        darbdavio_top_Atlyginimas: [
            "Miestas",
            "Atlyginimas",
            "Darbo aprašymas",
        ],
        darbuotojo_top_Atlyginimas: [
            "Miestas",
            "Atlyginimas",
            "Aprašymas",
        ],
        darbdavio_top_Aprasymas: [
            "Atlyginimas",
            "Aprašymas",
            "Papildomi klausimai",
        ],
        darbuotojo_top_Aprasymas: [
            "Atlyginimas",
            "Aprašymas",
            "Kontaktai",
        ],
        darbdavio_top_Klausimai: [
            "Darbo aprašymas",
            "Papildomi klausimai",
            "Galiojimo laikas",
        ],
        darbdavio_top_SkelbimoLaikas: [
            "Darbo aprašymas",
            "Papildomi klausimai",
            "Galiojimo laikas",
        ],
        darbo_pozicijos: [
            "Jaunesnysis darbuotojas",
            "Vidurinės grandies vadovas",
            "Vyresnysis vadovas",
            "Aukščiausios grandies vadovas",
            "Bet kuris",
        ],
        sutarties_pobudis: [
            "Darbo sutartis",
            "Individuali veikla",
            "Verslo liudijimas",
            "Praktikos ar savanorystės sutartis",
            "Bet kuri sutarties rūšis",
        ],
    }
}
