import React, { useState, useContext, useEffect } from 'react';
import TaskContext from '../AppStates';
import { useTranslation, Trans } from "react-i18next";

const setLogin = () => { return 'login'; }
// const access_token = ()=>{  GetData('@access_token')}

const Logins = async (acc) => {

  let access_token = acc.data.access_token
  const tokenUrl = acc.routas + 'users/access-user';
  const requestOptions = {
    method: 'GET',
  };
  try {
    if (access_token && access_token != '' && access_token != 'undefined') {
      const response = await fetch(`${tokenUrl}/${access_token}`, requestOptions)
      const json = await response.json();
      if (response.status == 200) {
        if (json.employer == false) {
          alert('only epmloyers!')
          acc.setTasks('login')
          return
        }
        acc.setUser(json)
        document.cookie = 'scobo_token=' + access_token
        if (json.finished_registration == true) {
          // console.log(acc.setTasks, 'matausi')
          try {
            acc.setTasks('viduje')
          }
          catch (e) { console.log(e) }
        }
        else if (json.message == 'bad token') {
          acc.setTasks('login')
        }
        else { acc.setTasks('reg') }
      }
      else {
        alert('only epmloyers!')
        acc.setTasks('login')
      }
    }
    else {
      acc.setTasks('login')
      return
    }



  } catch (error) {
    console.log(error)
    return 'login'
  }

}
export default Logins




