import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import { TaskContext } from "../AppStates";
import Modal from 'react-modal';
const StartSkelbimas = () => {
    const { user, routas, setTasks } = useContext(TaskContext)
    const [pavadinimas, setPavadinimas] = useState('')
    const [apie, setApie] = useState('')
    const [url, setUrl] = useState('')
    const [sk, setSK] = useState('')
    const [pareigos, setPareigos] = useState('')
    const { t } = useTranslation()
    const [sritysO, setsritysO] = useState('')
    const [sritysD, setsritysD] = useState('')
    const [darboP, setDarboP] = useState('')
    const [sutartis, setSutartis] = useState('')
    const [etatas, setEtatas] = useState('')
    const [currentKalba, setCurrentKalba] = useState('')
    const [kita_kalba, setKita_kalba] = useState('')
    const [miestas, setMiestas] = useState('')
    const [checked, setChecked] = useState(false)
    const [checked2, setChecked2] = useState(false)
    const [checked3, setChecked3] = useState(false)
    const [checked4, setChecked4] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [cv, setCv] = useState(false)
    const [linkedin, setLinkedin] = useState(false)
    const [nuo, setNuo] = useState('')
    const [iki, setIki] = useState('')
    const [darboA, setDarboA] = useState('')
    const [klausimai, setKlausimai] = useState('')
    const [kontaktinis, setKontaktinis] = useState('')
    const [date, setDate] = useState('')
    const [pasirinktasLygis, setPasirinktasLygis] = useState({})

    const [kalba, setKalba] = useState([])
    const [vezimeliai, setVezimeliai] = useState(false)
    const [atvirumo_tekstas, setAtvirumo_tekstas] = useState(false)
    const [komforto, setKomforto] = useState(false)
    var sritys = []
    var darboPozicija = []
    var sutartisD = []
    var etatasO = []
    var kalbaO = []
    var miestasO = []
    var kalbosLygiai = t('kalbos_lygiai')
    t('darbo_sritis').forEach((e, i) => {
        sritys.push({ value: i, label: e })
    })
    sritys = sritys.sort((a, b) => a.label > b.label ? 1 : -1)
    t('darbo_pozicijos').forEach((e, i) => {
        darboPozicija.push({ value: i, label: e })
    })

    t('sutartis').forEach((e, i) => {
        sutartisD.push({ value: i, label: e })
    })
    t('etatas').forEach((e, i) => {
        etatasO.push({ value: i, label: e })
    })
    t('kalbos').forEach((e, i) => {
        if (i != 4)
            kalbaO.push({ value: i, label: e })
    })
    t('miestai').forEach((e, i) => {
        miestasO.push({ value: i, label: e })
    })
    const apdoroti = (item) => {
        if (item == null) { return }
        if (item != null || item != '')
            try {
                let data = ''
                item.forEach((e, i) => {
                    if (i != item.length - 1) {
                        data += e.value + ','
                    } else { data += e.value }
                })
                return data
            }
            catch (e) { console.log(e) }
    }
    const apdorotiK = (item) => {
        if (item == null) { return }

        if (item != null || item != '' || item != null)
            try {
                let data = ''
                item.forEach((e, i) => {
                    if (i != item.length - 1) {
                        data += e.value + ':' + pasirinktasLygis.pasirinktasLygis[e.label] + ','
                    } else { data += e.value + ':' + pasirinktasLygis.pasirinktasLygis[e.label] }
                })
                return data
            }
            catch (e) { console.log(e) }
    }
    const create = async () => {
        console.log(kita_kalba)
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:
                JSON.stringify({
                    atlygis: nuo + '-' + iki,
                    darbo_sritis: apdoroti(sritysD),
                    pareigos: pareigos,
                    darbo_pozicija: apdoroti(darboP),
                    sutarties_pobudis: apdoroti(sutartis),
                    etatas: apdoroti(etatas),
                    kalbos_ir_ju_lygiai: apdorotiK(kalba),
                    miestas: apdoroti(miestas) + `${checked ? ',visi_miestai' : ''}` + `${checked2 ? ',nuotoliniu' : ''}` + `${checked4 ? ',hibridinis' : ''}`,
                    darbo_aprasymas: darboA,
                    kontaktinis_el_pastas: kontaktinis,
                    galiojimo_laikas: date,
                    papildomi_klausimai: klausimai,
                    is_CV: cv,
                    is_Linkedin: linkedin,
                    kita_kalba: kita_kalba,
                    atvirumo_tekstas: atvirumo_tekstas,
                    pritaikyta_judeti_vezimelyje: vezimeliai,
                    reguliarios_komforto_pertraukos: komforto,
                })
        };
        const create_listing = routas + 'skelbimai/' + user._id;
        try {
            console.log(JSON.parse(requestOptions.body))
            const response = await fetch(create_listing, requestOptions)
            const json = await response.json().then(data => {
                if (data.statusCode != 500) {
                    setTasks('viduje')

                    //   isRegistered()
                }
                //         return
            })
        } catch (error) {
            console.error(error);
        }
    };
    const update = async () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:
                JSON.stringify({
                    darbo_sritis: apdoroti(sritysO),
                    company_name: pavadinimas,
                    about_company: apie,
                    company_url: url,
                    finished_registration: true
                })
        };
        let update_employe = routas + 'users/update/' + user.access_token;
        console.log(JSON.parse(requestOptions.body))
        try {
            const response = await fetch(update_employe, requestOptions)
            const json = await response.json().then(data => {
                console.log(data)
            })
        } catch (error) {
            console.error(error);
        }
    };
    console.log(kita_kalba)
    return (
        <form onSubmit={(e) => {
            update()
            create()
            e.preventDefault()

        }}>
            <div className=" centerContainer">
                <div className="rightContainer1">
                    <h2> {t('apie_imone')}</h2>
                    <div className="regCon">
                        <input
                            required
                            className="input"
                            placeholder={t('imones_pavadinimas')}
                            value={pavadinimas}
                            onChange={(e) => { setPavadinimas(e.target.value) }}
                        />
                        <textarea
                            type="textarea"
                            className="textarea"
                            placeholder={t('apie_imone')}
                            value={apie}
                            onChange={(e) => { setApie(e.target.value) }}
                        />
                        <input
                            className="input"
                            placeholder={t('svetaine')}
                            value={url}
                            onChange={(e) => { setUrl(e.target.value) }}
                        />
                        <input
                            className="input"
                            type={'number'}
                            placeholder={t('darbuotoju_sk')}
                            value={sk}
                            onChange={(e) => { setSK(e.target.value) }}
                        />
                        <Select
                            className="select"
                            placeholder={t('veiklos_sritys')}
                            isMulti={true}
                            defaultValue={sritysO}
                            onChange={setsritysO}
                            options={sritys}
                        />
                    </div>
                </div>


                <div className="rightContainer1">
                    <h2>{t('Prideti_Skelbima')}</h2>
                    <div className="forma">
                        <div className="checkIt">
                            <Select
                                required
                                placeholder={t('darbdavio_top_steps')[0]}
                                className="select"
                                isMulti={true}
                                defaultValue={sritysD}
                                onChange={setsritysD}
                                options={sritys}
                            />
                            <input
                                className="hiddenCheck"
                                onChange={(e) => { e.preventDefault() }}
                                required
                                value={sritysD} />
                        </div>
                        <input
                            // placeholder={t('darbdavio_top_steps')[1]}
                            required
                            className="input"
                            placeholder={t('darbo_pareigos')}
                            value={pareigos}
                            onChange={(e) => { setPareigos(e.target.value) }}
                        />
                        <div className="checkIt">
                            <Select
                                placeholder={t('darbdavio_top_steps')[2]}
                                className="select"
                                isMulti={true}
                                defaultValue={darboP}
                                onChange={setDarboP}
                                options={darboPozicija}
                            />
                            <input
                                className="hiddenCheck"
                                onChange={(e) => { e.preventDefault() }}
                                required
                                value={darboP} />
                        </div>
                        <div className="checkIt">
                            <Select
                                placeholder={t('darbdavio_top_Pozicija')[2]}
                                className="select"
                                isMulti={true}
                                defaultValue={sutartis}
                                onChange={setSutartis}
                                options={sutartisD}
                            />
                            <input
                                className="hiddenCheck"
                                onChange={(e) => { e.preventDefault() }}
                                required
                                value={sutartis} />
                        </div>
                        <div className="checkIt">
                            <Select
                                placeholder={t('darbdavio_top_Sutarties')[2]}
                                className="select"
                                isMulti={true}
                                defaultValue={etatas}
                                onChange={setEtatas}
                                options={etatasO}
                            />
                            <input
                                className="hiddenCheck"
                                onChange={(e) => { e.preventDefault() }}
                                required
                                value={etatas} />
                        </div>
                        <div className="checkIt">
                            <Select
                                placeholder={t('darbdavio_top_Miestas')[0]}
                                className="select"
                                isMulti={true}
                                defaultValue={kalba}
                                onChange={(value) => {
                                    if (value.length >= kalba.length) {
                                        setModalIsOpen(true)
                                        setCurrentKalba(value[value.length - 1])
                                    }
                                    setKalba(value)
                                    console.log('kalba', kalba)
                                    console.log('value', value)
                                }}
                                options={kalbaO}
                            />
                            <input
                                className="hiddenCheck"
                                onChange={(e) => { e.preventDefault() }}
                                required
                                value={kalba} />
                        </div>
                        <input
                            className="input"
                            type={'text'}
                            defaultValue={kita_kalba}
                            placeholder={t('kita') + ' ' + t('kalba_filtr')}
                            onChange={(e) => { setKita_kalba(e.target.value) }}
                        />
                        <div className="checkIt">
                            <Select
                                placeholder={t('darbdavio_top_Miestas')[1]}
                                className="select"
                                isMulti={true}
                                defaultValue={miestas}
                                onChange={setMiestas}
                                options={miestasO}
                            />
                            {/* <input
                                className="hiddenCheck"
                                onChange={(e) => { e.preventDefault() }}
                                required
                                value={miestas} /> */}
                        </div>
                        <div className="flex column">
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={checked}
                                    onChange={() => {
                                        setChecked(!checked)
                                    }}
                                />
                                {t('visi_miestai')}
                            </label>
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={checked2}
                                    onChange={() => {
                                        setChecked2(!checked2)
                                    }}
                                />
                                {t('nuotoliniu')}
                            </label>
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={checked4}
                                    onChange={() => {
                                        setChecked4(!checked4)
                                    }}
                                />
                                {t('hibridinis_darbas')}
                            </label>
                        </div>
                        <h3 className="columnName">{t('atlyginimas')}</h3>
                        <input
                            type="number"
                            className="input"
                            placeholder={t('nuo')}
                            value={nuo}
                            onChange={(e) => { setNuo(e.target.value) }}
                        />
                        <input
                            type="number"
                            className="input"
                            placeholder={t('iki')}
                            value={iki}
                            onChange={(e) => { setIki(e.target.value) }}
                        />
                        <h3 className="columnName">{t('darbdavio_top_Atlyginimas')[2]}</h3>
                        <textarea
                            type="textarea"
                            className="textarea"
                            value={darboA}
                            onChange={(e) => { setDarboA(e.target.value) }}
                        />
                        <input type="email"
                            required
                            className="input"
                            placeholder={t('kontaktinis')}
                            value={kontaktinis}
                            onChange={(e) => { setKontaktinis(e.target.value) }}
                        />
                        <h3 className="columnName">{t('darbdavio_top_SkelbimoLaikas')[1]}</h3>
                        <textarea
                            type="textarea"
                            className="textarea"
                            value={klausimai}
                            onChange={(e) => { setKlausimai(e.target.value) }}
                        />
                        <div className="flex column">
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={cv}
                                    onChange={() => {
                                        setCv(!cv)
                                    }}
                                />
                                {t('reikalingas_CV')}
                            </label>
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={linkedin}
                                    onChange={() => {
                                        setLinkedin(!linkedin)
                                    }}
                                />
                                {t('reikalingas_Linkedin')}
                            </label>
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={komforto}
                                    onChange={() => {
                                        setKomforto(!komforto)
                                    }}
                                />
                                {t('reguliarios_komforto_pertraukos')}
                            </label>
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={vezimeliai}
                                    onChange={() => {
                                        setVezimeliai(!vezimeliai)
                                    }}
                                />
                                {t('pritaikyta_judeti_vezimelyje')}
                            </label>
                            <label className="checkBox">
                                <input
                                    type={'checkbox'}
                                    checked={atvirumo_tekstas}
                                    onChange={() => {
                                        setAtvirumo_tekstas(!atvirumo_tekstas)
                                    }}
                                />
                                {t('norite_teskstas_atsirastu')}
                                <span> "{t('atviri_poreikiams')}"</span>
                            </label>
                        </div>
                        <h3 className="columnName">{t('darbdavio_top_SkelbimoLaikas')[2]}</h3>
                        <div className="dateMargin">
                            <div className="checkIt">
                                <DatePicker
                                    className={'input calenderOuter'}
                                    calendarClassName={'calenderInner'}
                                    value={date}
                                    onChange={setDate} />
                                <input
                                    className="hiddenCheck"
                                    onChange={(e) => { e.preventDefault() }}
                                    required
                                    value={date} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="mainBtn selfCenter" onClick={(e) => {
                // console.log(e)

            }}>
                {t('issaugoti')}
            </button>
            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen(false) }}
            >
                <div
                    className="centerContainer column">

                    <div className="closeBtn">
                        <button onClick={() => {
                            setModalIsOpen(false)
                            let last = document.querySelectorAll('[aria-label="Remove ' + currentKalba.label + '"]')
                            last[0].click()
                        }}>✖</button>
                    </div>
                    <h3>{currentKalba.label}</h3>
                    <div
                        className="flex column radioContainer"
                        onChange={(e) => {
                            setPasirinktasLygis(prevState => ({
                                pasirinktasLygis: {
                                    ...prevState.pasirinktasLygis,
                                    [currentKalba.label]: e.target.defaultValue
                                }
                            }))
                        }}>
                        <div className="radioMargin">
                            <label><input type="radio" value={0} name="kalbos_lygiai" /> {kalbosLygiai[0]}</label>
                        </div>
                        <div className="radioMargin">
                            <label><input type="radio" value={1} name="kalbos_lygiai" /> {kalbosLygiai[1]}</label>
                        </div>
                        <div className="radioMargin">
                            <label><input type="radio" value={2} name="kalbos_lygiai" /> {kalbosLygiai[2]}</label>
                        </div>
                        <div className="radioMargin">
                            <label><input type="radio" value={3} name="kalbos_lygiai" /> {kalbosLygiai[3]}</label>
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            setModalIsOpen(false)
                            console.log(pasirinktasLygis, kalba)

                        }}
                        className="mainBtn modalBtn">{t('issaugoti')}</button>
                </div>

            </Modal>
        </form >
    )
}
export default StartSkelbimas